import React from 'react';
import {
  DealerModel,
  ModelTag,
  OffersModel,
} from '../../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../../utils/app-constants';
import { DealerInformation } from './dealer-information';
import { OfferDetailsCard } from './offer-details-card';
import { OfferDisplayHead } from './offer-display-head';
import { ServiceOfferDetailsCard } from './service-offer-details-card';
import { StyledOfferCardWrapper } from './styled';

export interface OfferInformationProps {
  dealer?: DealerModel;
  formType: string;
  modelTag?: ModelTag;
  offer?: OffersModel;
  handleClose: () => void;
  onHandleDealerUrlTracking: (name: string) => void;
}

const OfferInformation = ({
  dealer,
  formType,
  handleClose,
  modelTag,
  offer,
  onHandleDealerUrlTracking,
}: OfferInformationProps) => {
  const isNotStandard = formType === AppConstants.NotStandardModelFormType;
  const isNationalOffersOnly =
    AppConstants.NationalOffersOnlyFormType === formType;

  return (
    <>
      {formType === AppConstants.OfferDetailsFormType ||
      formType === AppConstants.ContactDealerFormType ||
      formType === AppConstants.GetAQuoteFormType ||
      isNotStandard ||
      isNationalOffersOnly ? (
        <StyledOfferCardWrapper>
          {offer && offer.modelKey !== AppConstants.AllOfferModelKey && (
            <OfferDisplayHead
              offer={offer}
              handleClose={handleClose}
              isDetailForm={
                formType === AppConstants.OfferDetailsFormType || isNotStandard
              }
              isNotStandardModel={isNotStandard}
            />
          )}

          {dealer &&
          formType !== AppConstants.OfferDetailsFormType &&
          !isNotStandard &&
          !isNationalOffersOnly ? (
            <>
              <DealerInformation
                dealer={dealer}
                onHandleDealerUrlTracking={onHandleDealerUrlTracking}
              />
            </>
          ) : (
            offer && <OfferDetailsCard offer={offer} modelTag={modelTag} />
          )}
        </StyledOfferCardWrapper>
      ) : formType === AppConstants.ScheduleServiceFormType ||
        formType === AppConstants.ScheduleServiceDetailFormType ? (
        <StyledOfferCardWrapper>
          {dealer && formType !== AppConstants.ScheduleServiceDetailFormType ? (
            <>
              <DealerInformation
                dealer={dealer}
                onHandleDealerUrlTracking={onHandleDealerUrlTracking}
              />
            </>
          ) : (
            offer && <ServiceOfferDetailsCard offer={offer} />
          )}
        </StyledOfferCardWrapper>
      ) : null}
    </>
  );
};

export default OfferInformation;
