export interface OfferTypeModel {
    readonly offerType: string;
    readonly offerName: string;
  }
  
  export const localOfferType: OfferTypeModel[] = [
    { offerType: 'lease', offerName: 'Lease' },
    { offerType: 'apr', offerName: 'APR' },
    { offerType: 'other', offerName: 'Other' },
  ];
  
  export const newLocalOfferType: OfferTypeModel[] = [
    { offerType: 'all', offerName: 'All Offers' },
    { offerType: 'apr', offerName: 'Finance' },
    { offerType: 'lease', offerName: 'Lease' },
    { offerType: 'other', offerName: 'Special Programs' },
  ];
  