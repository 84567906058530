import React from 'react';
import { ModelConfig } from '../typing/model-config';


export const ModelsConfigContext = React.createContext<ModelConfig | undefined>(
  undefined,
);

export function useModelsConfig(): ModelConfig {
  const modelsConfig = React.useContext(ModelsConfigContext);
  if (!modelsConfig) {
    throw new Error('No defined models config');
  }
  return modelsConfig;
}
