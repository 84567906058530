import {
  Breakpoints,
  Close,
  CTA,
  Disclaimer,
  DisclaimerModal,
  useIsMobile,
} from '@vw-marketing/us-components';
import React, { useEffect } from 'react';
import ModalDisclaimerWrapper from '../../components/modal-disclaimer-wrapper';
import { useDisclaimerConfiguration } from '../../hooks/use-disclaimer-configuration';
import { DisclaimerKeys, getDisclaimersByPage } from '../../utils/disclaimer';
import { FilterTabs } from '../offers-section/filter/filter-tabs';
import { NewOffersContainer } from '../offers-section/offers-container/NewOffersContainer';
import { ModalHeader } from './modal-header';
import {
  CloseWrapper,
  StyledContentWrapper,
  StyledModalWrapper,
} from './styled';
import { useStore } from '../../hooks-store/store';
import { NoDealers } from '../../components/no-dealers';
import IncentiveConstants from '../../hooks-store/incentive-store/actions/incentive-constants';

import { useIsNationalOffers } from '../../hooks/use-is-national-offer';
import { useHistory } from '../../hooks/use-history';

interface OffersModalProps {
  modelName: string;
  done?: (result?: unknown) => void;
  showCloseButton?: boolean;
  trim?: string;
  isTrimLevel?: boolean;
}

export const OffersModal: React.FC<OffersModalProps> = ({
  modelName,
  done,
  showCloseButton = false,
  trim,
  isTrimLevel,
}) => {
  const [store, { [IncentiveConstants.ResetStore]: resetStore }] = useStore();
  const isNationalOffer = useIsNationalOffers();
  const { disclaimers } = useDisclaimerConfiguration();
  const landingDisclaimers = getDisclaimersByPage(
    DisclaimerKeys.LandingKey,
    disclaimers,
  );
  const history = useHistory();
  const isMobile = useIsMobile(Breakpoints.b560);

  const handleClose = () => {
    done?.();
  };

  useEffect(() => {
    return () => {
      resetStore(history);
    };
  }, []);

  return (
    <>
      <StyledModalWrapper className="modal-wrapper" isMobile={isMobile}>
        {showCloseButton && (
          <CloseWrapper>
            <CTA
              tag="button"
              emphasis="none"
              onClick={handleClose}
              icon={<Close variant="default" />}
              iconPosition="top"
            />
          </CloseWrapper>
        )}
        <ModalHeader
          modelKey={modelName}
          trim={trim}
          isTrimLevel={isTrimLevel}
        />
        <FilterTabs />
        <StyledContentWrapper>
          <div>
            {!store.dealers.length && !isNationalOffer ? (
              <NoDealers />
            ) : (
              <NewOffersContainer />
            )}
          </div>
        </StyledContentWrapper>
      </StyledModalWrapper>
      <ModalDisclaimerWrapper>
        <DisclaimerModal disclaimers={landingDisclaimers as Disclaimer[]} />
      </ModalDisclaimerWrapper>
    </>
  );
};
