import {
  Breakpoints,
  BreakpointWrapper,
  ChevronDown,
  Divider,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import React, { useState } from 'react';
import { AttributeModel } from '../../../../hooks-store/typings/incentive-store';
import { useStickyHeader } from '../../../../hooks/use-sticky-header';
import { useTranslations } from '../../../../hooks/use-translations';
import { FilterAttribute } from '../filter-attribute';
import {
  StyledColorCheckboxes,
  StyledFilterDropdownButton,
  StyledFilterLabelContainer,
  StyledIcon,
  StyledModelYear,
  StyledModelYearContainer,
} from './styles';

interface FilterModelYearProps {
  attributes: AttributeModel[];
  appliedFilters?: AttributeModel[];
  modalActive: boolean;
}

const FilterModelYear: React.FC<FilterModelYearProps> = props => {
  const { attributes, appliedFilters, modalActive } = props;
  const [hideOptions, setHideOptions] = useState(false);
  const { isSticky } = useStickyHeader();
  const translations = useTranslations().filtersSection.filtersLabels;

  return (
    <StyledModelYearContainer>
      <StyledModelYear>
        <StyledFilterLabelContainer>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h3}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize20,
                lineHeight: TextLineHeight.TextLineHeight24,
              },
            }}
          >
            {translations.modelYear}
          </TextCustom>
          <StyledFilterDropdownButton
            onClick={() => setHideOptions(!hideOptions)}
            className="filter-model-year-button"
            isSticky={isSticky}
            modalActive={modalActive}
            isActive={hideOptions}
          >
            <BreakpointWrapper max={Breakpoints.b960}>
              <StyledIcon
                className={`filter-model-year-icon ${hideOptions && 'active'}`}
                hideOptions={hideOptions}
              >
                <div className={`animation ${hideOptions && 'active'}`}>
                  <ChevronDown />
                </div>
              </StyledIcon>
            </BreakpointWrapper>
          </StyledFilterDropdownButton>
        </StyledFilterLabelContainer>
        <BreakpointWrapper min={Breakpoints.b960}>
          <Divider />
        </BreakpointWrapper>
        <StyledColorCheckboxes isSticky={isSticky}>
          {!hideOptions &&
            attributes
              .sort((a, b) => Number(b.value) - Number(a.value))
              .map(modelYear => (
                <FilterAttribute
                  key={modelYear.value}
                  attribute={modelYear}
                  appliedFilters={appliedFilters}
                  category={'modelYear'}
                />
              ))}
        </StyledColorCheckboxes>
      </StyledModelYear>
      <BreakpointWrapper max={Breakpoints.b960}>
        <Divider />
      </BreakpointWrapper>
    </StyledModelYearContainer>
  );
};

export default FilterModelYear;
