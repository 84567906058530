import React, { useEffect, useRef, useState } from 'react';

import {
  Breakpoints,
  Button,
  ButtonAppearance,
  DisableFocusWrapper,
  FindCar,
  Link,
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
  TextCustom,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import isBrowser from 'is-browser';

import { defaultTranslation } from '../../config/translations';
import { FeatureAppTrackingManager } from '../../hooks/use-tracking-manager';
import { ModalZipContainerOffers } from './modal-zip-container-offers';
import {
  ModalZipWrapper,
  StyledButtonContainer,
  StyledButtonGroupContainer,
  StyledButtonGroupWrapper,
  StyledHeaderContainer,
  StyledSubHeaderContainer,
  StyledZipContainerGroup,
} from './styled';
import { onVWBasicLayerLoadEvent } from '../../tagging/tagging-helpers';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureAppModel } from '../../hooks/use-featureapp-model';
import { useFeatureServices } from '../../hooks/use-feature-services';
import InputZipOffers from '../input-zip-offers';
import { getModelNameByCarlineId } from '../../utils/general';
// import {scrollToDocumentTop} from '../../utils/general';

interface ModalZipOffersProps {
  readonly active: boolean;
  readonly trackingManager: FeatureAppTrackingManager;
  readonly offerId?: number;
}

export const ModalZipOffers = React.memo(
  ({ active, trackingManager, offerId }: ModalZipOffersProps) => {
    const [modalModelKey, setModalModelKey] = useState<string>('');
    const childRef = useRef<any>();

    const { modelName } = useFeatureAppConfig();
    const modelOverview = useFeatureAppModel() ?? {};
    const { 'fa-content-service': contentService } = useFeatureServices();
    const faContent = contentService?.content as any;
    const translations = defaultTranslation.modals.modalZip;

    useEffect(() => {
      if (Object.keys(modelOverview).length > 0) {
        if (faContent?.carlineId) {
          const model = getModelNameByCarlineId(
            faContent.carlineId,
            modelOverview,
          );
          setModalModelKey(model.toLowerCase());
        } else {
          setModalModelKey(modelName ?? '');
        }
      }
    }, [modelOverview, faContent?.carlineId, modelName]);

    useEffect(() => {
      if (isBrowser) {
        active
          ? (document.body.style.overflow = 'hidden')
          : (document.body.style.overflow = 'inherit');
        active && onVWBasicLayerLoadEvent(trackingManager);
      }
      return () => {
        if (isBrowser) {
          document.body.style.overflow = 'inherit';
          // setTimeout(function() {
          //   scrollToDocumentTop();
          // }, 1000);
        }
      };
    }, [active]);

    return (
      <ModalZipWrapper>
        <ModalZipContainerOffers active={active}>
          <StyledZipContainerGroup>
            <StyledHeaderContainer>
              <FindCar variant="large" />
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize32,
                    fontWeight: TextWeight.bold,
                  },
                  [Breakpoints.b1280]: {
                    fontSize: TextSize.TextSize44,
                  },
                }}
              >
                {translations.title}
              </TextCustom>
            </StyledHeaderContainer>
            <StyledSubHeaderContainer>
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontWeight: TextWeight.regular,
                    fontSize: TextSize.TextSize20,
                    textAlign: TextAlignment.center,
                  },
                }}
              >
                {translations.description}
              </TextCustom>
            </StyledSubHeaderContainer>
            <StyledButtonGroupWrapper>
              <StyledButtonGroupContainer>
                <InputZipOffers
                  placeholder="Enter ZIP Code"
                  label="Enter your ZIP code"
                  errorText={'Invalid ZIP code'}
                  maxLength={5}
                  ref={childRef}
                  modelKey={modalModelKey}
                  isModal
                  offerId={offerId}
                />
                <StyledButtonContainer>
                  <DisableFocusWrapper>
                    {/* @ts-ignore */}
                    <Button
                      appearance={ButtonAppearance.Primary}
                      onClick={() => childRef.current.applyZip()}
                      stretchContent
                    >
                      <Text
                        color={TextColor.inherit}
                        appearance={TextAppearance.copy100}
                      >
                        {translations.goCtaLabel}
                      </Text>
                    </Button>
                  </DisableFocusWrapper>
                </StyledButtonContainer>
              </StyledButtonGroupContainer>
            </StyledButtonGroupWrapper>
            <DisableFocusWrapper>
              <Text
                appearance={TextAppearance.copy100}
                tag={TextTag.p}
                bold
                textAlign={TextAlignment.center}
              >
                {/* @ts-ignore */}
                <Link href={'/en/privacy.html'} target={'_blank'} isInline>
                  {translations.privacyLinkCta}
                </Link>
              </Text>
            </DisableFocusWrapper>
          </StyledZipContainerGroup>
        </ModalZipContainerOffers>
      </ModalZipWrapper>
    );
  },
);
