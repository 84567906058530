import React, { useEffect, useState } from 'react';

import {
  DisableFocusWrapper,
  LinkButton,
  TextCustom,
} from '@vw-marketing/us-components';
import { useApolloClient } from '@apollo/client';

import { CarModelConfig } from '../../typing/main';
import {
  DealerModel,
  ModelTag,
} from '../../hooks-store/typings/incentive-store';
import {
  DEFAULT_OFFERS_REDIRECT_PAGE,
  generateOffersPageUrl,
  getOffersByModel,
  offerViewMoreOffersStyle,
} from './helpers';
import { LoaderSpinner } from '../../components/loader-spinner';
import { ModalDisplayMode } from '../../typing/modal-display';
import { modalsData } from '../../store/local-storage';
import { Offer } from '../../typing/offer';
import {
  onHandlePromotionButtonTracking,
  onHandlerBasicVehicleLink,
  onHandlerDataLoaded,
} from '../../tagging/tagging-helpers';
import { StyledTrimModuleOffersContainer } from './styles';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureServices } from '../../hooks/use-feature-services';
import { useStore } from '../../hooks-store/store';
import { useTrackingManager } from '../../hooks/use-tracking-manager';
import { useTranslations } from '../../hooks/use-translations';
import ModalDisplay from '../../components/modal-display';
import OffersBox from '../../components/offers-box';
import TrimModuleNoOffers from '../../components/trim-module-no-offers';
import TrimModuleOfferCard from '../../components/trim-module-offer-card';

interface TrimModuleState {
  appStatus: 'loading' | 'error' | 'success' | 'initial' | 'empty';
  error?: boolean;
  dealer?: DealerModel;
  offers?: Offer[];
  dealers?: DealerModel[];
  modelConfig?: CarModelConfig;
}

const TrimModule = (): JSX.Element => {
  const [store] = useStore();
  const [trimModuleAppState, setTrimModuleAppState] = useState<TrimModuleState>(
    { appStatus: 'initial' },
  );
  const {
    trimModuleConfig,
    offersRedirectPageConfig = DEFAULT_OFFERS_REDIRECT_PAGE,
  } = useFeatureAppConfig();

  const { trimModuleMode } = useTranslations();
  const client = useApolloClient();
  const navigateService = useFeatureServices()['navigation-service'];
  const trackingManager = useTrackingManager();
  const { salesEventOffersIds, modelOrder } = store.fetchedSharedConfigs;
  const modelKeyString: string =
    trimModuleAppState.modelConfig?.offerModelKey || '';
  const model = modelOrder.find(
    item => item.modelKey.trim() === modelKeyString.trim(),
  );
  const modelTag: ModelTag | undefined = model ? model.modelTag : undefined;
  /**
   * handles view details modal
   * @param offer
   */
  const onHandleViewDetails = (offer: Offer) => {
    modalsData({
      offer,
      modelOffersIDs: salesEventOffersIds[modelKeyString],
      modelTag,
      dealer: trimModuleAppState.dealer,
      active: true,
      mode: ModalDisplayMode.TRIM_MODULE_DETAILS,
      model: {
        ...trimModuleAppState.modelConfig,
        carImage:
          trimModuleConfig?.carImage ||
          trimModuleAppState.modelConfig?.carImage ||
          '',
        carImageAlt:
          trimModuleConfig?.carAlt ||
          trimModuleAppState.modelConfig?.carImageAlt ||
          '',
      },
    });

    trackingManager &&
      onHandlePromotionButtonTracking(
        trimModuleMode.offerButtonLabel,
        undefined,
        { ...trimModuleAppState, offer },
        trackingManager,
      );
  };
  /**
   * Handle redirect to more offers
   */
  const onHandleMoreOffers = () => {
    const tier1Redirect = generateOffersPageUrl(
      offersRedirectPageConfig,
      trimModuleConfig?.carlineName || '',
      trimModuleConfig?.dealer?.postalcode || '',
      navigateService,
    );
    trackingManager &&
      onHandlerBasicVehicleLink(
        tier1Redirect?.createHref(),
        trimModuleMode.viewMoreOffersCta,
        trackingManager,
        trimModuleAppState,
      );
    tier1Redirect?.push();
  };

  useEffect(() => {
    setTrimModuleAppState({ appStatus: 'loading' });
    getOffersByModel(client, trimModuleConfig)
      .then(offers => {
        setTrimModuleAppState({
          appStatus: offers.offers.length ? 'success' : 'empty',
          ...offers,
        });
        trackingManager && onHandlerDataLoaded(trackingManager, offers);
      })
      .catch(error => {
        console.log(error);
        setTrimModuleAppState({ appStatus: 'error' });
      });
  }, []);

  // Sort the offers by putting the ones that are part of sales event first
  useEffect(() => {
    if (
      trimModuleAppState.appStatus === 'success' &&
      trimModuleAppState.offers?.length &&
      trimModuleAppState.modelConfig?.offerModelKey
    ) {
      const modelKey: string | undefined =
        trimModuleAppState.modelConfig?.offerModelKey;
      const offersIDs = salesEventOffersIds[modelKey];

      if (offersIDs) {
        const prioritizedModels = trimModuleAppState.offers.filter(offer =>
          offersIDs.includes(offer.dealId),
        );
        const remainingModels = trimModuleAppState.offers.filter(
          offer => !offersIDs.includes(offer.dealId),
        );

        setTrimModuleAppState(prevState => ({
          ...prevState,
          appStatus: 'success',
          offers: [...prioritizedModels, ...remainingModels],
        }));
      }
    }
  }, [trimModuleAppState.appStatus]);

  switch (trimModuleAppState.appStatus) {
    case 'loading':
      return <LoaderSpinner label="Loading Special Offers" />;
    case 'error':
      return <></>;
    case 'empty':
      return <></>;
    case 'success':
      return (
        <OffersBox title={trimModuleMode.offerButtonLabel}>
          <StyledTrimModuleOffersContainer className="trim-module_ offers-container">
            {trimModuleAppState.offers?.length ? (
              trimModuleAppState.offers?.map(offer => (
                <TrimModuleOfferCard
                  modelTag={modelTag}
                  modelKey={trimModuleAppState.modelConfig?.offerModelKey || ''}
                  offer={offer}
                  onViewDetails={onHandleViewDetails}
                  key={offer.dealId}
                  translation={trimModuleMode.offerCard}
                />
              ))
            ) : (
              <TrimModuleNoOffers message={trimModuleMode.noOffersAvailable} />
            )}
            <div className="trim-module_view-more-offers">
              <DisableFocusWrapper>
                {/* @ts-ignore */}
                <LinkButton
                  isInline
                  onClick={onHandleMoreOffers}
                  target="_blank"
                >
                  <TextCustom {...offerViewMoreOffersStyle}>
                    {trimModuleMode.viewMoreOffersCta}
                  </TextCustom>
                </LinkButton>
              </DisableFocusWrapper>
            </div>
          </StyledTrimModuleOffersContainer>
          <ModalDisplay />
        </OffersBox>
      );
    default:
      return <></>;
  }
};

export default TrimModule;
