import React, { memo, useState } from 'react';

import {
  Breakpoints,
  DisableFocusWrapper,
  LinkButton,
  Offers,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import { useTrackingManager } from '../../../../hooks/use-tracking-manager';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import {
  DealerModel,
  ModelTag,
  OffersModel,
} from '../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../hooks/use-feature-app-config';
import { useIsNationalOffers } from '../../../../hooks/use-is-national-offer';
import { useTranslations } from '../../../../hooks/use-translations';
import { AppConstants } from '../../../../utils/app-constants';
import { clearText, getDateFormat } from '../../../../utils/general';
import { onHandleFormButtonTracking } from '../../../../tagging/tagging-helpers';
import OfferButtonGroup, { OfferButton } from './button-group';
import {
  StyledBlackContainer,
  StyledInformationOfferContainer,
  StyledInformationWrapper,
  StyledModelNameWrapper,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferSubtitle,
  StyledOfferTitle,
  StyledOfferTypeLabel,
  StyledOfferTypeLabelWrapper,
  StyledOpacityWrapper,
  StyledSectionButton,
  StyledShowOfferDetails,
  StyledTextGradient,
} from './newStyles';

interface OfferCardProps {
  readonly isTier1?: boolean;
  readonly modelInventoryHref?: string;
  readonly modelTag?: ModelTag;
  readonly offer: OffersModel;
  readonly opacity?: boolean;
}

export const NewOfferCard = memo(
  ({
    modelInventoryHref,
    modelTag,
    offer,
    opacity,
  }: OfferCardProps): JSX.Element | null => {
    const [
      store,
      { [IncentiveConstants.SetModalFormData]: setModalFormData },
    ] = useStore();
    const { offersWithoutNationalText, vehicle } = useFeatureAppConfig();
    const { salesEventOffersIds } = store.fetchedSharedConfigs;
    const modelKeyString = offer.modelKey || '';
    const isSalesEventOffer =
      salesEventOffersIds[modelKeyString]?.includes(offer.dealId) ?? false;
    const [showGradient, setShowGradient] = useState(false);
    const informationWrapperHeight = 449;

    const trackingManager = useTrackingManager();
    const is1600Res = useIsMobile(Breakpoints.b1600);

    const isNotStandardModel = typeof offer.modelCustomCta !== 'undefined';
    const isNationalOffersOnly = useIsNationalOffers();
    const vwPartnerProgramLink = 'https://vwpartnerprogram.com';
    const isActiveNational = useIsNationalOffers();

    const vehicleDealer: DealerModel = vehicle?.dealer
      ? ({
          ...vehicle.dealer,
        } as any)
      : store.dealer;

    const setContactDealerFormData = () => {
      setModalFormData(
        offer,
        modelTag,
        vehicleDealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.contactDealer,
        trackingManager,
        store,
      );
    };

    const setOfferTypeText = () => {
      if (offer.dealLocaleType) {
        return offer.dealLocaleType.includes('National') &&
          offersWithoutNationalText?.includes(offer.dealId)
          ? ''
          : `${offer.dealLocaleType} Offer.`;
      }
      return '';
    };

    const setNationalOffersOnlyFormData = () => {
      setModalFormData(
        offer,
        modelTag,
        undefined,
        AppConstants.NationalOffersOnlyFormType,
        AppConstants.NationalOffersOnlyFormName,
      );
    };

    const setNotStandardFormData = () => {
      setModalFormData(
        offer,
        modelTag,
        vehicleDealer,
        AppConstants.NotStandardModelFormType,
        AppConstants.NotStandardModelFormName,
      );
    };

    const setGetAQuoteFormData = () => {
      setModalFormData(
        offer,
        modelTag,
        vehicleDealer,
        AppConstants.GetAQuoteFormType,
        AppConstants.GetAQuoteFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.getAQuote,
        trackingManager,
        store,
      );
    };

    const setDetailsFormData = (name: string) => {
      if (isNationalOffersOnly) {
        setNationalOffersOnlyFormData();
      } else if (isNotStandardModel) {
        setNotStandardFormData();
      } else {
        setModalFormData(
          offer,
          modelTag,
          vehicleDealer,
          AppConstants.OfferDetailsFormType,
          AppConstants.OfferDetailsFormName,
        );
      }
      onHandleFormButtonTracking(offer, name, trackingManager, store);
    };

    const setLinkTrackingData = (ctaLabel: string, url: string) => {
      onHandleFormButtonTracking(offer, ctaLabel, trackingManager, store, url);
    };
    /**
     * Set the gradient div component state
     * @param el Div element
     */
    const setGradient = (el: HTMLDivElement) => {
      if (!el) {
        return;
      }

      setShowGradient(
        el.getBoundingClientRect().height >= informationWrapperHeight,
      );
    };

    const isPartnerProgram = offer.dealTitle.includes('Partner Program');
    const translations = useTranslations().offersSection.modelOfferSection
      .offerCard;

    const inventoryRedirectButtonConfig: OfferButton = {
      type: 'link',
      url: modelInventoryHref,
      ctaLabel: translations.inventoryRedirect,
      onClick: setLinkTrackingData.bind(
        null,
        translations.inventoryRedirect,
        modelInventoryHref || '',
      ),
    };

    const contactDealerButtonConfig: OfferButton = {
      type: 'button',
      onClick: setContactDealerFormData.bind(null),
      ctaLabel: translations.contactDealer,
    };

    const getAQuoteButtonConfig: OfferButton = {
      type: 'button',
      onClick: setGetAQuoteFormData.bind(null),
      ctaLabel: translations.getAQuote,
    };

    const certificateButtonConfig: OfferButton = {
      type: 'link',
      url: vwPartnerProgramLink,
      ctaLabel: translations.getCertificate,
      onClick: setLinkTrackingData.bind(
        null,
        translations.getCertificate,
        vwPartnerProgramLink,
      ),
    };

    const noStandardButtonConfig: OfferButton = {
      ...offer.modelCustomCta,
      type: 'link',
      onClick: setLinkTrackingData.bind(
        null,
        offer?.modelCustomCta?.ctaLabel || '',
        offer?.modelCustomCta?.url || '',
      ),
    };

    return (
      <StyledOfferCardWrapper className="offerCard">
        <StyledInformationWrapper wrapperHeight={informationWrapperHeight}>
          <StyledOpacityWrapper isOpacity={opacity} />
          <div
            style={{
              position: 'absolute',
              top: '0',
              bottom: '0',
            }}
            ref={(el: HTMLDivElement) => {
              setGradient(el);
            }}
          />
          {isSalesEventOffer && (
            <StyledOfferTypeLabelWrapper>
              <StyledBlackContainer className="offer-tag">
                <StyledOfferTypeLabel backgroundColor={modelTag?.background}>
                  <TextCustom
                    appearance={TextAppearance.label100}
                    tag={TextTag.span}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight20,
                        color: TextColor.inherit,
                        fontWeight: TextWeight.bold,
                      },
                    }}
                  >
                    <Offers variant="small" /> {modelTag?.label}
                  </TextCustom>
                </StyledOfferTypeLabel>
              </StyledBlackContainer>
            </StyledOfferTypeLabelWrapper>
          )}
          <StyledInformationOfferContainer>
            <StyledModelNameWrapper className="notranslate">
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize16,
                    lineHeight: TextLineHeight.TextLineHeight24,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.modelKey &&
                offer.modelKey === AppConstants.AllOfferModelKey
                  ? offer.modelDisplayName
                  : `${offer.dealCarYear[0] || ''} ${offer.modelDisplayName ||
                      ''} ${offer.dealCarTrimName[0] || ''}`}
              </TextCustom>
            </StyledModelNameWrapper>
            <StyledOfferTitle>
              {!offer.dealTitle.includes('\n') ? (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.h2}
                  style={{
                    [Breakpoints.default]: {
                      fontWeight: TextWeight.bold,
                      fontSize: is1600Res
                        ? TextSize.TextSize16
                        : TextSize.TextSize24,
                      lineHeight: is1600Res
                        ? TextLineHeight.TextLineHeight20
                        : TextLineHeight.TextLineHeight29,
                    },
                  }}
                >
                  {clearText(offer.dealTitle)}
                </TextCustom>
              ) : (
                offer.dealTitle.split('\n').map(line => (
                  <TextCustom
                    appearance={TextAppearance.headline200}
                    tag={TextTag.h2}
                    style={{
                      [Breakpoints.default]: {
                        fontWeight: TextWeight.bold,
                        fontSize: is1600Res
                          ? TextSize.TextSize16
                          : TextSize.TextSize24,
                        lineHeight: is1600Res
                          ? TextLineHeight.TextLineHeight20
                          : TextLineHeight.TextLineHeight29,
                      },
                    }}
                  >
                    {clearText(line)}
                  </TextCustom>
                ))
              )}
            </StyledOfferTitle>
            <StyledOfferSubtitle>
              {!offer.dealSubtitle.includes('\n') ? (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealSubtitle}
                </TextCustom>
              ) : (
                offer.dealSubtitle.split('\n').map(line => (
                  <TextCustom
                    appearance={TextAppearance.headline200}
                    tag={TextTag.p}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight18,
                        fontWeight: TextWeight.regular,
                      },
                    }}
                  >
                    {line}
                  </TextCustom>
                ))
              )}
            </StyledOfferSubtitle>
            <StyledOfferMicroText>
              {offer.dealAdditionalTerms && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealAdditionalTerms}
                </TextCustom>
              )}
              {offer.dealExclusions && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealExclusions}
                </TextCustom>
              )}
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.dealLocaleType === 'Daa'
                  ? `Local Offer. Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`
                  : `${setOfferTypeText()} Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`}
              </TextCustom>
            </StyledOfferMicroText>
            {showGradient && <StyledTextGradient />}
          </StyledInformationOfferContainer>
          <StyledShowOfferDetails>
            <DisableFocusWrapper>
              {/*@ts-ignore*/}
              <LinkButton
                onClick={() => setDetailsFormData(translations.offerDetails)}
                isInline
              >
                <TextCustom
                  appearance={TextAppearance.label100}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight16,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.bold,
                    },
                  }}
                >
                  {translations.offerDetails}
                </TextCustom>
              </LinkButton>
            </DisableFocusWrapper>
          </StyledShowOfferDetails>
        </StyledInformationWrapper>
        <StyledSectionButton>
          {isNotStandardModel ? (
            <OfferButtonGroup firstButtonConfig={noStandardButtonConfig} />
          ) : (
            <OfferButtonGroup
              firstButtonConfig={
                !isPartnerProgram
                  ? !isActiveNational
                    ? getAQuoteButtonConfig
                    : inventoryRedirectButtonConfig
                  : certificateButtonConfig
              }
              secondButtonConfig={
                isPartnerProgram && isActiveNational
                  ? inventoryRedirectButtonConfig
                  : !isActiveNational
                  ? contactDealerButtonConfig
                  : undefined
              }
            />
          )}
        </StyledSectionButton>
      </StyledOfferCardWrapper>
    );
  },
);
