import React from 'react';

import { LayoutWrapper, StyledLandingWrapper } from './styled';
import {
  LoaderContainer,
  LoaderSpinner,
} from '../../components/loader-spinner';
import { LocalOffers } from './local-offers';
import { useStore } from '../../hooks-store/store';

export type DealerView = 'map' | 'list';

interface Tier1SimplifiedVersionPros {
  readonly modelKey: string;
  readonly modelName: string;
  readonly carlineId?: string;
}

export const Tier1SimplifiedVersion: React.FC<Tier1SimplifiedVersionPros> = props => {
  const [store] = useStore();
  const { modelKey, modelName, carlineId } = props;

  return (
    <StyledLandingWrapper>
      {store.isStoreCloned && !store.pendingProcessState ? (
        <LayoutWrapper>
          <LocalOffers
            offersData={store.offersData}
            dealer={store.dealer}
            modelKey={modelKey}
            modelName={modelName}
            modelsConfig={store.modelsConfig}
            carlineId={carlineId}
          />
        </LayoutWrapper>
      ) : (
        <LoaderContainer>
          <LoaderSpinner />
        </LoaderContainer>
      )}
    </StyledLandingWrapper>
  );
};
