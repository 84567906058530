import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import {History} from 'history';
import _ from 'lodash';

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const addUrlParamWithPush = (
  history: History,
  key: string,
  value: string
) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = {...query, [key]: value};
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.push(path);
};

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const removeUrlParam = (history: History, key: string) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = _.omit(query, [key]);
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.push(path);
};


/**
 * Get the correct format for the searh from router history
 * @param history Router history
 */
export const getHistorySearch = (history: History) => {
  const search = history.location.search;

  const query = queryString.parse(decodeURI(search));
  delete query['---'];
  const formattedSearch = isEmpty(query) ? '' : `?${queryString.stringify(query)}`;

  return formattedSearch.replace(/-app/gi, '');
};

/**
 * Get a zip using the location search from react
 * @param history React history
 */
export const getUrlZip = (history: History): string => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query.zip) {
    const validZipRegex = /^[0-9]{5}$/;
    if (validZipRegex.test(query.zip)) {
      return query.zip;
    }
  }

  return '';
};

/**
 * Get model using hash property and set it like a model filter selected
 * @param history Router history
 */
export const setModelFromHash = (history: History) => {
  const hash = window.location.hash;
  if (hash) {
    const model = hash.split('=').pop();
    if (model) {
      addUrlParam(history, 'model', model.replace(/-/g, ' '));
    }
  }
};

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const addUrlParam = (history: History, key: string, value: string) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = {...query, [key]: value};
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.replace(path);
};


/**
 * Get value from query parameter using a key
 * @param history React history
 * @param key Key value for query parameter obj
 */
export const getUrlParam = (history: History, key: string): string | null => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query[key]) {
    return query[key];
  }
  return null;
};
