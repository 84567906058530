import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import HandleDataLoad from '../../components/handle-data-load';
import { AppConstants } from '../../utils/app-constants';
import isBrowser from 'is-browser';
import { useFeatureAppModel } from '../../hooks/use-featureapp-model';
import { localModelOrder, ModelOrder } from '../../config/model-order';
import { ShowroomModel } from '../../typing/main';
import { ErrorComponent } from '../../components/error-component';
import { Tier1SimplifiedVersion } from '../../pages/tier1-simplified-version';
import { SkeletonShowroom } from '../../components/skeleton-showroom';
import { useStore } from '../../hooks-store/store';

interface ShowroomModuleProps {
  baseUrl?: string;
}

declare var vwa_d6_cms: any;

const ShowroomModule: React.FC<ShowroomModuleProps> = ({ baseUrl }) => {
  const [moduleModelKey, setModuleModelKey] = useState<string>('');
  const [moduleModelName, setModuleModelName] = useState<string>('');
  const [showroomModel, setShowroomModel] = useState<ShowroomModel | undefined>(
    undefined,
  );
  const [error, setError] = useState<boolean>(false);
  const modelOverview = useFeatureAppModel() ?? {};
  const [store] = useStore();
  const { modelOrder: modelOrderConfig } = store.fetchedSharedConfigs;

  useEffect(() => {
    if (isBrowser) {
      try {
        if (
          modelOverview && 
          typeof vwa_d6_cms !== undefined &&
          vwa_d6_cms &&
          vwa_d6_cms.browserRegistry &&
          vwa_d6_cms.browserRegistry.singletonInstances &&
          vwa_d6_cms.browserRegistry.singletonInstances
            .CarConfigurationService &&
          vwa_d6_cms.browserRegistry.singletonInstances.CarConfigurationService.instance.get()
        ) {
          const curShowroomModel = vwa_d6_cms.browserRegistry.singletonInstances.CarConfigurationService.instance.get();

          // Just for the local version
          //const curShowroomModel = {
          //  category: 'private',
          //  carlineId: '31691',
          //  salesGroupId: '38940',
          //  trimName: 'S',
          //  modelYear: '2020',
          //  exteriorId: 'F14 0Q0Q',
          //  interiorId: 'F56     BG',
          //  buildabilityStatus: 'buildable',
          //  modelId: 'BW22VS',
          //};
          if (!curShowroomModel || !curShowroomModel.carlineId)
            throw new Error('No showroom carlineId defined');
          const useModelOrder = modelOrderConfig
            ? modelOrderConfig
            : localModelOrder;

          const modelOrderEle: ModelOrder | undefined = useModelOrder.find(
            (model: ModelOrder) =>
              model.carlineId &&
              model.carlineId === curShowroomModel?.carlineId,
          );

          setTimeout(() => {
            setModuleModelKey(modelOrderEle ? modelOrderEle.modelKey : '');
          }, 2500);

          setModuleModelName(
            modelOrderEle ? modelOrderEle.modelName.replace(/ /g, '-') : '',
          );
          setShowroomModel(curShowroomModel);
        }
      } catch (e) {
        console.log('Global vwa_d6_cms ', e);
        setError(true);
      }
    }
  }, []);

  if (error) {
    return <ErrorComponent />;
  }
  return (
    <Switch>
      <Route exact path="/">
        <HandleDataLoad
          page={AppConstants.Tier1SimplifiedVersion as string}
          baseUrl={baseUrl}
          modelKey={moduleModelKey}
        >
          {moduleModelKey ? (
            <Tier1SimplifiedVersion
              modelKey={moduleModelKey}
              modelName={moduleModelName}
              carlineId={showroomModel?.carlineId}
            />
          ) : (
            <SkeletonShowroom />
          )}
        </HandleDataLoad>
      </Route>
    </Switch>
  );
};

export default ShowroomModule;
