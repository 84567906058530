import {Logger} from '@feature-hub/core';
import { BffLoggerCall, BffLoggerCallLevel } from '../typing/inventory';


const logPrefixes: {readonly [level in BffLoggerCallLevel]: string} = {
  error: 'BFF Error:',
  warn: 'BFF Warning:'
};

export function logBffLoggerCalls(
  bffLoggerCalls: BffLoggerCall[],
  logger: Logger
): void {
  for (const call of bffLoggerCalls) {
    // TODO: Remove the "MOFA" prefix when the bugfix for the consumer logger in
    // the CMS has been released.
    // See https://git.sinnerschrader.com/vwa/vwacms18/merge_requests/1635
    logger.debug('MOFA', logPrefixes[call.level], ...call.args);
  }
}
