import React from 'react';
import { Container } from '@vw-marketing/us-components';

interface SalesEventHomeProps {
  baseUrl?: string;
}

const SalesEventHome: React.FC<SalesEventHomeProps> = ({ baseUrl }) => {
  return (
    <Container>
      <h1>Sales Event FA - Home</h1>
    </Container>
  );
};

export default SalesEventHome;
