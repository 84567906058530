import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HandleDataLoad from '../../components/handle-data-load';
import { AppConstants } from '../../utils/app-constants';
import Landing from '../../pages/landing';
interface SpecialOffersTier1Props {
  baseUrl?: string;
}

const SpecialOffersTier1: React.FC<SpecialOffersTier1Props> = ({ baseUrl }) => {
  return (
    <Switch>
      <Route exact path="/">
        <HandleDataLoad
          page={AppConstants.PageLandingTier1 as string}
          baseUrl={baseUrl}
        >
          <Landing />
        </HandleDataLoad>
      </Route>
    </Switch>
  );
};

export default SpecialOffersTier1;
