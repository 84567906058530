// This is the file in charge of merge all the available stores

import { useEffect, useState } from 'react';
import { FeatureAppMemoryStoreV1 } from '../store/feature-app-memory-store';
import { useFeatureServices } from '../hooks/use-feature-services';
import { AppConstants } from '../utils/app-constants';
import getIncentiveActions from './incentive-store/actions/incentive-actions';
import { getInitialState } from './incentive-store/initial-state';
import { StoreModel } from './typings/store';

let globalState: StoreModel;
let listeners: any = [];
let actions: any = {};

/**
 * Clean the store if necessary
 * @param memoryStore Store service
 */
export const cleanStore = (
  memoryStore: FeatureAppMemoryStoreV1<StoreModel>,
) => {
  const memoryStoreAux = memoryStore.getState();
  if (
    memoryStoreAux &&
    memoryStoreAux.status === AppConstants.StoreStatusLoaded &&
    !memoryStoreAux.ssr
  ) {
    memoryStore.setState({ ...getInitialState() });
  }
  globalState = { ...getInitialState() };
  listeners = [];
};

export const useStore = (shouldListen = true): [StoreModel, any] => {
  // This is the way to synchronize SSR and CSR, the store state is created
  // in base of feature-app-memory-store to keep the information in both sides
  const { 'feature-app-memory-store': memoryStore } = useFeatureServices();
  const initialState = { ...getInitialState(), ...memoryStore.getState() };

  memoryStore.setState(initialState);
  const [state, setState] = useState<StoreModel>(initialState);

  const dispatch = (actionIdentifier: string, payload: any): void => {
    const newState = actions[actionIdentifier](globalState, payload);
    globalState = { ...globalState, ...newState };

    // Change the state of the memory store to transfer it from one server-side
    // render pass to another.
    memoryStore.setState(globalState);

    for (const listener of listeners) {
      listener(globalState);
    }

    return newState;
  };

  useEffect(() => {
    if (shouldListen) {
      listeners.push(setState);
    }

    return () => {
      if (shouldListen) {
        listeners = listeners.filter((li: any) => li !== setState);
      }
    };
  }, [setState, shouldListen]);

  return [
    state,
    {
      ...getIncentiveActions(dispatch),
    },
  ];
};

export const initStore = (userActions: any) => {
  actions = { ...actions, ...userActions };
};
