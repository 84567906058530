import React, { useEffect } from 'react';

import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { ModalContainerProps } from '@vw-marketing/us-components';

import { CarModelConfig } from '../../typing/main';
import {
  DealerModel,
  ModelTag,
} from '../../hooks-store/typings/incentive-store';
import { ModalTrimModuleDetails } from '../modal-trim-module-details';
import { ModalTrimModuleDetailsTranslations } from '../../typing/translations';
import { Offer } from '../../typing/offer';
import { useFeatureServices } from '../../hooks/use-feature-services';

interface ModalWithLayerProps extends ModalContainerProps {
  dealer?: DealerModel;
  model?: CarModelConfig;
  modelOffersIDs?: number[];
  modelTag?: ModelTag;
  offer?: Offer;
  translations: ModalTrimModuleDetailsTranslations;
  onLoadHandler?: () => void;
}

export const ModalWithLayer: React.FC<ModalWithLayerProps> = ({
  active,
  dealer,
  model,
  modelOffersIDs,
  modelTag,
  offer,
  onCloseHandler,
  onLoadHandler,
  translations,
}) => {
  const layerManager = useFeatureServices()['layer-manager'];

  useEffect(() => {
    if (active) {
      layerManager.openFocusLayer(
        ModalTrimModuleDetails,
        {
          text: ' layer',
          index: 3,
          dealer,
          translations,
          onCloseHandler,
          offer,
          model,
          modelTag,
          modelOffersIDs,
          active,
        },
        {
          onClose: onCloseHandler,
          size: FocusLayerSizeV2.C,
        },
      );
      onLoadHandler && onLoadHandler();
    }
  }, [active]);

  return <div></div>;
};
