import {
  BreakpointWrapper,
  Breakpoints,
  CCustomerCenter,
  CInternet,
  Container,
  ContainerGutter,
  DisableFocusWrapper,
  Link,
  PinPlace,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import React from 'react';
import { DealerModel } from '../../../../../../hooks-store/typings/incentive-store';
import { stringToPhoneFormat } from '../../../../../../utils/general';
import DealerInformationDetail from '../../../../../dealer-information-detail';
import {
  StyledDealerInformationWrapper,
  StyledDealerNameContainer,
  StyledDealerWrapper,
  StyledLinkWrapper,
} from './styled';

interface DealerInformationProps {
  readonly dealer: DealerModel;
  onHandleDealerUrlTracking: (name: string) => void;
}

export const DealerInformation: React.FC<DealerInformationProps> = (
  props,
): JSX.Element | null => {
  const { dealer, onHandleDealerUrlTracking } = props;

  return (
    <>
      <StyledDealerNameContainer>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36,
            },
          }}
        >
          at <span className="notranslate">{dealer.name}</span>
        </TextCustom>
      </StyledDealerNameContainer>
      <StyledDealerWrapper>
        <StyledDealerInformationWrapper>
          <Container gutter={ContainerGutter.static300} wrap="always">
            <DealerInformationDetail
              Icon={PinPlace}
              label1={<span className="notranslate">{dealer?.address1}</span>}
              label2={
                <span className="notranslate">{`${dealer?.city}, ${dealer?.state} ${dealer?.postalcode}`}</span>
              }
            />
            <BreakpointWrapper max={Breakpoints.b560}>
              <DisableFocusWrapper>
                <Link href={dealer ? `tel: ${dealer.phone}` : ''}>
                  <DealerInformationDetail
                    Icon={CCustomerCenter}
                    label1={
                      <span className="notranslate">
                        {stringToPhoneFormat(dealer ? dealer.phone : '')}
                      </span>
                    }
                  />
                </Link>
              </DisableFocusWrapper>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b560}>
              <DealerInformationDetail
                Icon={CCustomerCenter}
                label1={
                  <span className="notranslate">
                    {stringToPhoneFormat(dealer ? dealer.phone : '')}
                  </span>
                }
              />
            </BreakpointWrapper>
            {dealer.url && dealer.url !== '' && (
              <DisableFocusWrapper>
                <DealerInformationDetail
                  Icon={CInternet}
                  textCustom={
                    <StyledLinkWrapper>
                      <TextCustom
                        appearance={TextAppearance.copy100}
                        style={{
                          [Breakpoints.default]: {
                            fontWeight: TextWeight.bold,
                            fontSize: TextSize.TextSize14,
                            lineHeight: TextLineHeight.TextLineHeight22,
                          },
                        }}
                      >
                        <Link
                          href={dealer.url}
                          target="_blank"
                          isInline
                          onClick={onHandleDealerUrlTracking.bind(
                            null,
                            'Visit Dealer Site',
                          )}
                        >
                          Visit Dealer Site
                        </Link>
                      </TextCustom>
                    </StyledLinkWrapper>
                  }
                />
              </DisableFocusWrapper>
            )}
          </Container>
        </StyledDealerInformationWrapper>
      </StyledDealerWrapper>
    </>
  );
};
