import isBrowser from 'is-browser';
import { fetchModelConfig } from '../fetch-data/fetch-model-config';

import { logBffLoggerCalls } from './log-bff-debug-logs';

import { useFeatureAppConfig } from './use-feature-app-config';
import { useFeatureServices } from './use-feature-services';
import { useLogger } from './use-logger';
import { useUniversalEffect } from './use-universal-effect';
import { useUniversalState } from './use-universal-state';
import { getStringifiedServiceConfigsServiceConfig } from '../utils/get-stringified-service-configs-service-config';
import { FeatureAppModelConfig } from '../typing/main';

export function useFeatureAppModel(): FeatureAppModelConfig | undefined {
  const [{ modelOverviewResult }, dispatch] = useUniversalState();
  //const {baseUrl} = useFeatureAppEnvironment();
  const { mockIds, mocksBaseUrl, faServicesUrl } = useFeatureAppConfig();
  const logger = useLogger();

  const {
    's2:async-ssr-manager': asyncSsrManager,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
  } = useFeatureServices();

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );

  useUniversalEffect(() => {
    if (modelOverviewResult) {
      if (!isBrowser) {
        return;
      }

      if (modelOverviewResult.status === 'failed' && modelOverviewResult.ssr) {
        logger.warn('SOFA SSR error detected, trying to fetch data again.');
      } else {
        return;
      }
    }

    dispatch({ type: 'fetchingModelOverview' });

    const fetchModelOverviewPromise = fetchModelConfig({
      serviceConfigsServiceConfig,
      mockIds,
      mocksBaseUrl,
      faServicesUrl,
    })
      .then(fetchedModelOverview => {
        dispatch({ type: 'fetchedModelOverview', fetchedModelOverview });
      })
      .catch(error => {
        if (error.bffLoggerCalls) {
          logBffLoggerCalls(error.bffLoggerCalls, logger);
        }

        logger.error(error);
        dispatch({ type: 'failedToFetchModelOverview', error });
      });

    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(fetchModelOverviewPromise);
    }
  }, []);

  return modelOverviewResult?.status === 'loaded'
    ? modelOverviewResult.modelOverview
    : undefined;
}
