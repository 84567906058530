import { SpecialOffersTranslations} from '../typing/translations';

export const defaultTranslation: SpecialOffersTranslations = {
  trimModuleMode: {
    viewMoreOffersCta: "View more offers",
    offerCard: {
      offerLocale: "$$OFFER_LOCALE$$ Offer. Offer ends $$OFFER_DATE$$",
      viewDetailsCta: "View offer details"
    },
    noOffersAvailable: "No offers available for this vehicle",
    offerButtonLabel: "Potential Available Offers"
  },
  modals: {
    modalZip: {
      title: 'Set your location',
      description: 'To find dealers, inventory, and special offers near you, we’ll need your zip code.',
      goCtaLabel: 'Go',
      privacyLinkCta: 'View Privacy Policy',
      inputMessage: {
        label: 'Enter your ZIP code',
        error: 'Invalid ZIP code',
      },
    },
    trimModuleDetails: {
      modalTitle: 'Offer Description',
      dealerDescription: 'Be sure to confirm with this dealer which advertised national, state or local offers, if any, they are participating in for this vehicle.',
      distance: "mi away",
      dealerTitle: ''
    }
  },
};
