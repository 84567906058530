import { logBffLoggerCalls } from './log-bff-debug-logs';

import fetchSharedConfigs from '../fetch-data/fetch-shared-configs';
import IncentiveConstants from '../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../hooks-store/store';
import { useFeatureServices } from './use-feature-services';
import { useLogger } from './use-logger';
import { useUniversalEffect } from './use-universal-effect';

export function useSharedConfigs(): void {
  const [
    _,
    { [IncentiveConstants.SetSharedConfigs]: setSharedConfigs },
  ] = useStore();
  const logger = useLogger();

  const {
    's2:async-ssr-manager': asyncSsrManager,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
  } = useFeatureServices();

  useUniversalEffect(() => {
    const fetchSharedConfigsPromise = fetchSharedConfigs(
      serviceConfigProvider,
      serverRequest,
    )
      .then(sharedConfigs => {
        setSharedConfigs(sharedConfigs);
      })
      .catch(error => {
        if (error.bffLoggerCalls) {
          logBffLoggerCalls(error.bffLoggerCalls, logger);
        }

        logger.error(error);
      });

    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(fetchSharedConfigsPromise);
    }
  }, []);
}
