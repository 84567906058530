import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { IpToZip } from '@volkswagen-onehub/zip-manager';
import { History } from 'history';
import isBrowser from 'is-browser';

import { AppConstants } from '../../utils/app-constants';

import { localModelOrder } from '../../config/model-order';
import { fetchInitialDataCSR, fetchInitialDataSSR } from '../../fetch-data';
import IncentiveConstants from '../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../hooks-store/store';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureServices } from '../../hooks/use-feature-services';
import { useIsNationalOffers } from '../../hooks/use-is-national-offer';
import { ShowroomModel } from '../../typing/main';
import { manageClassById } from '../../utils/general';
import { getStringifiedServiceConfigsServiceConfig } from '../../utils/get-stringified-service-configs-service-config';
import { getUrlParam, getUrlZip } from '../../utils/navigation-url';
import { ErrorMessage } from '../error-message';
import { PageLoad } from './page-load';

interface HandleDataLoadProps {
  readonly children: React.ReactNode;
  readonly baseUrl?: string;
  readonly page: string;
  readonly showRoomModel?: ShowroomModel;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
}

// This variable helps to prevent unnecessary calls to the endpoints
let fetchDataInProcess: boolean = false;

const HandleDataLoad: React.FC<HandleDataLoadProps> = ({
  children,
  page,
  modelKey,
  trim,
  isTrimLevel,
}): JSX.Element | null => {
  const isNationalOffer = useIsNationalOffers();

  // App store
  const [
    store,
    { [IncentiveConstants.SetInitialData]: setInitialData },
  ] = useStore();

  const {
    modelOrder: modelOrderConfig,
    mappedTrimName,
    specialEventConfig,
    salesEventOffersIds,
  } = store.fetchedSharedConfigs!;

  // Call different services used to apply fetches and update the store data
  const { faServicesUrl, mockIds, mocksBaseUrl } = useFeatureAppConfig();
  const history = useHistory();

  const modelOrder = modelOrderConfig || localModelOrder;
  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
  } = useFeatureServices();

  const {
    configs: { 'iptozip': ipToZip, 'graphql-server': graphQLServer },
  } = serviceConfigProvider;

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );
  const [urlZip] = useState(getUrlZip(history));
  const [urlOffer] = useState<any>(getUrlParam(history, 'offerId'));

  useEffect(() => {
    if (page === AppConstants.PageLandingTier1) {
      manageClassById(
        true,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    } else {
      manageClassById(
        false,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    }
  }, [page]);

  // Result of fetch data in the SSR
  let initialData: any = null;
  // Tracking on SSR

  useEffect(() => {
    if (page === AppConstants.PageLandingTier1) {
      manageClassById(
        true,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    } else {
      manageClassById(
        false,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    }
  }, [page]);

  try {
    // Get data using SSR or CSR according to the app load
    if (!store.status && !isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      initialData = fetchInitialDataSSR(
        page,
        urlZip,
        faServicesUrl!,
        mockIds,
        mocksBaseUrl,
        modelOrder,
        mappedTrimName,
        specialEventConfig,
        modelKey,
        trim,
        isTrimLevel,
      );
    } else if (!store.status && isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      const zip =
        page === AppConstants.OffersModal
          ? (!isNationalOffer && (zipManager.getZipCookie() || urlZip)) ||
            undefined
          : (!isNationalOffer && (urlZip || zipManager.getZipCookie())) ||
            undefined;
      fetchInitialDataCSR({
        page,
        zip,
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        zipManager,
        setInitialData,
        history: history as History,
        faServicesUrl: faServicesUrl ?? '',
        mockIds,
        mocksBaseUrl,
        modelOrder,
        mappedTrimName,
        ipToZip: ipToZip as IpToZip,
        isNationalOffer: isNationalOffer,
        specialEvent: specialEventConfig,
        graphQLServer,
        modelKey,
        trim,
        isTrimLevel,
        salesEventOffersIds,
      });
    }
  } catch (error) {
    return <ErrorMessage />;
  }
  // Validate the application data before rendering the components
  const applicationData = initialData ? initialData : store;

  // If some error is detected an error page should be shown to the users
  if (
    applicationData &&
    applicationData.errorHandling.length &&
    !applicationData.zipNoDealers
  ) {
    return <ErrorMessage />;
  }

  return (
    <>
      {applicationData &&
        applicationData.status === AppConstants.StoreStatusLoaded &&
        faServicesUrl !== '' && (
          <PageLoad
            page={page}
            applicationData={applicationData}
            urlZip={
              page === AppConstants.OffersModal
                ? zipManager.getZipCookie()
                : urlZip
            }
            offerId={urlOffer}
            modelKey={modelKey}
            isNationalOffers={isNationalOffer}
            specialEvent={specialEventConfig}
            trim={trim}
            isTrimLevel={isTrimLevel}
          >
            {(fetchDataInProcess = false)}
            {children}
          </PageLoad>
        )}
      {faServicesUrl === '' && 'Error - no faservice'}
    </>
  );
};

export default HandleDataLoad;
