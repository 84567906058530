import React, { useEffect, useState } from 'react';

import { AppConstants } from '../../utils/app-constants';
import { ErrorComponent } from '../../components/error-component';
import { getModelNameByCarlineId } from '../../utils/general';
import { LoaderContainer, LoaderSpinner } from '../../components/loader-spinner';
import { OffersModal } from '../../pages/offers-modal';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureAppModel } from '../../hooks/use-featureapp-model';
import { useFeatureServices } from '../../hooks/use-feature-services';
import HandleDataLoad from '../../components/handle-data-load';

interface SpecialOffersModalProps {
  baseUrl?: string;
  done?: (result?: unknown) => void;
}

const SpecialOffersModal: React.FC<SpecialOffersModalProps> = ({
  baseUrl,
  done,
}) => {
  const [modalModelKey, setModalModelKey] = useState<string>('');

  const { 'fa-content-service': contentService } = useFeatureServices();
  const { modelName, trimName, source } = useFeatureAppConfig();
  const faContent = contentService?.content as any;
  const modelOverview = useFeatureAppModel() ?? {};

  const isTrimLevel = () => {
    const isBuilderTrimLevel =
      (faContent?.source === 'configure' || faContent?.source === 'showroom') &&
      faContent?.type === 'trim';
    return source === 'inventoryResults' || isBuilderTrimLevel;
  };

  useEffect(() => {
    if (Object.keys(modelOverview).length > 0) {
      if (faContent?.carlineId) {
        const model = getModelNameByCarlineId(
          faContent.carlineId,
          modelOverview,
        );
        setModalModelKey(model.toLowerCase());
      } else {
        setModalModelKey(modelName ?? '');
      }
    }
  }, [modelOverview]);

  if (
    !modelName &&
    !faContent &&
    !faContent?.trims &&
    !faContent?.carlineId &&
    !faContent?.modelYear &&
    !modalModelKey
  ) {
    return <ErrorComponent />;
  }
  return modalModelKey.length ? (
    <HandleDataLoad
      baseUrl={baseUrl}
      page={AppConstants.OffersModal as string}
      modelKey={modalModelKey}
      trim={trimName ?? faContent?.trimId}
      isTrimLevel={isTrimLevel()}
    >
      {modalModelKey ? (
        <OffersModal
          modelName={modalModelKey}
          trim={trimName ?? faContent?.trimId}
          done={done}
          showCloseButton={faContent !== undefined}
          isTrimLevel={isTrimLevel()}
        />
      ) : (
        <LoaderContainer>
          <LoaderSpinner />
        </LoaderContainer>
      )}
    </HandleDataLoad>
  ) : null;
};

export default SpecialOffersModal;
