import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledOffersSectionWrapper: any = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 20px 10px 20px;
  width: 100%;

  @media (max-width: ${Breakpoints.b560}px) {
    padding: 0;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    flex-wrap: nowrap;
  }
`;

interface StyledBottomContainerProps {
  stickyHeaderToggle?: boolean;
}

export const StyledBottomContainer: any = styled.div<
  StyledBottomContainerProps
>`
  ${({ stickyHeaderToggle }: StyledBottomContainerProps) =>
    stickyHeaderToggle ? 'margin-top: 30px;' : `margin-top: 271px;`}

  @media (max-width: ${Breakpoints.b560}px) {
    ${({ stickyHeaderToggle }: StyledBottomContainerProps) =>
      stickyHeaderToggle ? 'margin-top: 0;' : 'margin-top: 200px;'}
  }
`;

export const OverflowWrapper: any = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

export const LayoutWrapper: any = styled.div<any>`
  display: flex;
  padding: 0;
  overflow-x: visible;
  padding-top: 30px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0 ${(props: any) => props.theme.size.grid002};
    padding-top: 30px;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding-top: 30px;
  }
`;

export const StyledOffersContainer: any = styled.div`
  width: 100%;
`;

export const StyledOffersTitle: any = styled.div`
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 20px 0;
  width: 100%;

  h1 {
    font-weight: normal;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    padding: 20px;
  }
`;
