import { TextAlignment, TextAppearance, TextColor, TextCustomProps, TextLineHeight, TextSize, TextTag, TextType, TextWeight } from '@vw-marketing/us-components';
import { getOfferDateFormat } from '../../utils/general';

const LOCALE_IDENTIFIER = '$$OFFER_LOCALE$$';
const DATE_IDENTIFIER = '$$OFFER_DATE$$';
export const getPrettyOfferLocale = (
  offerLocale: string,
  offerDate: string,
  offerTemplate: string,
) => {
  let prettyLocale = offerTemplate.replace(LOCALE_IDENTIFIER, offerLocale);
  prettyLocale = prettyLocale.replace(
    DATE_IDENTIFIER,
    getOfferDateFormat(offerDate),
  );
  return prettyLocale;
};

export const offerCardTitleStyle: TextCustomProps = {
    appearance: TextAppearance.headline200,
    style: {
      fontSize: TextSize.TextSize14,
      fontWeight: TextWeight.bold,
      textAlign: TextAlignment.left,
      lineHeight: TextLineHeight.TextLineHeight18,
      color: TextColor.primary,
      fontType: TextType.Headline
    },
    tag: TextTag.h2,
  };

  export const offerCardLegalStyle: TextCustomProps = {
    appearance: TextAppearance.headline200,
    style: {
      fontSize: TextSize.TextSize14,
      textAlign: TextAlignment.left,
      lineHeight: TextLineHeight.TextLineHeight18,
      color: TextColor.primary,
      fontType: TextType.Headline,
      fontWeight: TextWeight.light
    },
    tag: TextTag.p,
  };

  export const offerCardLocaleStyle: TextCustomProps = {
    appearance: TextAppearance.headline200,
    style: {
      fontSize: TextSize.TextSize14,
      textAlign: TextAlignment.left,
      lineHeight: TextLineHeight.TextLineHeight18,
      color: TextColor.inherit,
      fontType: TextType.Headline

    },
    tag: TextTag.span,
  };

  export const offerViewDetailsStyle: TextCustomProps = {
    style: {
      fontSize: TextSize.TextSize14,
      textAlign: TextAlignment.left,
      lineHeight: TextLineHeight.TextLineHeight16,
      color: TextColor.inherit,
      fontType: TextType.Copy,
      fontWeight: TextWeight.regular

    },
    tag: TextTag.span,
  };


  
