// This code is responsible for creating the first configuration of the store and push it to the store.tsx

import { initStore } from '../store';
import { getReducer } from './reducer';

/**
 * Push the initial data and functionality to the store.tsx to use it through the app
 */
const configureStore = () => {
  const actions = { ...getReducer() };

  // Initialize the store with default values
  initStore(actions);
};

export default configureStore;
