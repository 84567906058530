import React from 'react';

import { CustomImage, TextCustom } from '@vw-marketing/us-components';
import { LayerCloseHandleV2 } from '@volkswagen-onehub/layer-manager';

import { AppConstants } from '../../utils/app-constants';
import { modalTitleTextStyle } from './helpers';
import { OfferDetailsCard } from '../offer-details-card';
import { StyledModalTrimModuleDetailsContainer } from './styles';
import { TrimModuleDetailsLayerState } from '../../typing/modal-display';
import ModalCloseButton from '../close-button';
import ModalDealerCard from '../modal-dealer-card';

export const ModalTrimModuleDetails = (
  state: TrimModuleDetailsLayerState,
  close: LayerCloseHandleV2<void>,
  // ,options: LayerRenderOptionsV2 // NOTE: Just for information about layers
): JSX.Element => {
  const {
    dealer,
    model,
    modelOffersIDs,
    modelTag,
    offer,
    translations,
  } = state;

  const onHandleInternalClose = () => {
    close && close();
    // onCloseHandler && onCloseHandler();
  };

  return (
    <>
      <ModalCloseButton onClick={onHandleInternalClose} />
      <StyledModalTrimModuleDetailsContainer className="modal-trim-module_container">
        <div className="trim-module-modal_title">
          <TextCustom {...modalTitleTextStyle}>
            {translations.modalTitle}
          </TextCustom>
        </div>
        <div className="trim-module-modal_image">
          <CustomImage
            src={model?.carImage || AppConstants.NoFoundImageError348x196}
            alt={model?.carImageAlt || ''}
            srcPlaceholder={AppConstants.PlaceHolder960x432}
          />
        </div>
        <div className="trim-module-modal_dealer-details">
          <ModalDealerCard dealer={dealer} translations={translations} />
        </div>
        <div className="trim-module-modal_offer-details">
          <OfferDetailsCard
            modelTag={modelTag}
            modelOffersIDs={modelOffersIDs}
            offer={offer!}
          />
        </div>
      </StyledModalTrimModuleDetailsContainer>
    </>
  );
};
