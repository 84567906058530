import { gql } from '@apollo/client';
// import { OfferRequest } from '../../typing/queries';

//https://vicci-prod-cdn.ngw6apps.io/?tenant=ihdcc-vw-us-en

// export const offerProductRequest: OfferRequest = {
// sdlPath: '/vwsdl/rest/offer/zip/$$ZIP_CODE$$.json',
//   serviceConfigsServiceConfig: '',
//   language: 'en',
//   ttl: "129600",
//   countryCode: "US", 
//   currency: "USD" 
// };
export const PRODUCT_OFFER_QUERY = gql`
  query productOfferQuery($zipCode: String!) {
    getSpecialOffersByZipCode(zipCode: $zipCode) {
      __typename
      dealAdditionalTerms
      dealCarModel
      dealCarModelName
      dealCarTrim
      dealCarYear
      dealCopy
      dealEndDate
      dealExclusions
      dealId
      dealLegal
      dealLocaleType
      dealOfferTypes
      dealPricePerMonth
      dealPriority
      dealSubtitle
      dealTitle

      offerLegal @client
      offerCopy @client
      localeType @client
      offerTitle @client
      offerSubtitle @client
      trimInventoryName @client

      dealCustomImageName
      dealDaaCode
      dealDaaName
      dealLocaleTypeOrder
      dealRate
      dealRegionCode
      dealRegionName
      dealStartDate
      dealTerm
      dealerCode
      dealerName
      stateCode
      stateName
      dealCustomImageLocation
    }
  }
`;
