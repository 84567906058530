import { styled } from '@vw-marketing/us-components';

export const StyledTrimModuleOfferCard = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column;

  .offer-title {
    margin-top: 18px;
  }

  .offer-subtitle {
    margin-top: 5px;
    p {
        font-weight: 300;
    }
  }

  .offer-locale-type {
    margin-top: 10px;
    color:  #758189;
    span {
      font-weight: 300;
    }
  }

  .offer-view-details {
    margin-top: 5px;
    color: #001E50;
  }
`;
