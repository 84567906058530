import {
  TextCustomProps,
  TextSize,
  TextAlignment,
  TextLineHeight,
  TextColor,
  TextType,
  TextWeight,
  TextTag,
} from '@vw-marketing/us-components';
import { Offer } from '../../typing/offer';
import { OffersRedirectPageConfig } from '../../typing/main';
import { NavigationServiceV1 } from '@volkswagen-onehub/navigation-service';

import { MODELS_CONFIG } from '../../store/queries/models-config';
import { PRODUCT_OFFER_QUERY } from '../../store/queries/offers';
import { ModuleTrimConfiguration } from '../../typing/trim-mode';
import { ApolloClient, DocumentNode } from '@apollo/client';
import { modelConfiguration } from '../../store/local-storage';
import { PRODUCT_NATIONAL_OFFER_QUERY } from '../../store/queries/national';
import { DealerModel } from '../../hooks-store/typings/incentive-store';

export const DISPLAYED_OFFERS = 3;

export const DEFAULT_OFFERS_REDIRECT_PAGE: OffersRedirectPageConfig = {
  pageName: 'offers',
  faName: 'US-SofaTier1V1',
  paramsKey: 'model',
};
export const getAllOffersByNationalModel = (
  modelKey: string,
  modelTrim: string,
  offers: Offer[],
) => {
  const filterByModel = (offer: Offer) => {
    const { dealCarModel, trimInventoryName} = offer;
    const isIncludedModel =
      dealCarModel.length && dealCarModel.includes(modelKey);
    const isIncludedTrim =  !trimInventoryName || trimInventoryName == modelTrim;
    return isIncludedModel && isIncludedTrim;
  };
  const modelOffers = offers.filter(filterByModel);
  return modelOffers;
};

/**
 * Selects by model and model year offers
 * @param modelKey 
 * @param modelYear 
 * @param offers 
 * @returns 
 */
export const getAllOffersByModelYear = (
  modelKey: string,
  modelYear: string,
  modelTrim: string, 
  offers: Offer[],
) => {
  const filterByModel = (offer: Offer) => {
    const { dealCarModel, dealCarYear, trimInventoryName} = offer;
    const isIncludedModel =
      dealCarModel.length && dealCarModel.includes(modelKey);
    const isIncludedYear =
      dealCarYear.length && dealCarYear.includes(modelYear);
    const isIncludedTrim = trimInventoryName ? trimInventoryName === modelTrim : true;
    return isIncludedModel && isIncludedYear && isIncludedTrim;
  };
  const modelOffers = offers.filter(filterByModel);
  return modelOffers;
};

/**
 * Selects dealer by id
 * @param dealerId 
 * @param dealers 
 * @returns  Dealer
 */
export const getDealerById = (dealerId: string, dealers: DealerModel[]) => {
  const dealer = dealers.find((dealer: DealerModel) => dealer.dealerid === dealerId);
  return dealer;
};

/**
 * Offers url generation with navigate service
 * @param config 
 * @param modelName 
 * @param zipCode 
 * @param service 
 * @returns 
 */
export const generateOffersPageUrl = (
  config: OffersRedirectPageConfig,
  modelName: string,
  zipCode: string,
  service: NavigationServiceV1,
) => {
  const { pageName, faName, paramsKey } = config;
  const urlParams = new URLSearchParams();
  if (modelName) {
    urlParams.set(paramsKey, modelName);
  }
  if (zipCode) {
    urlParams.set('zip', zipCode);
  }
  const navigateToTier1 = service.navigateTo(pageName, {
    [faName]: `/?${urlParams.toString()}`,
  });
  return navigateToTier1;
};

const OFFER_BY_MODEL_ERRORS = {
  NO_CONFIG: ' No Model configuration available, ',
  MODELS_CONFIG: 'Error getting models config, ',
  DEALERS_DATA: 'Error getting dealers data, ',
  OFFERS_DATA: 'Error getting offers data, ',
  NO_CARLINE_CONFIG:
    'Error: Carline is no defined on models config, CarlineId: ',
  NO_NATIONAL_OFFERS: 'Error: Getting national offers',
  NO_DEALER: "Error: Dealer data was no declared"
};
const requestQuery = async (
  query: DocumentNode,
  variables: any,
  errorKey: string,
  dataKey: string,
  client: ApolloClient<any>,
) => {
  try {
    const data = await client.query({
      query,
      variables,
    });
    if (data?.error || !data[dataKey]) throw Error('');
    return data[dataKey];
  } catch (error: any) {
    throw Error(OFFER_BY_MODEL_ERRORS[errorKey] + error.message);
  }
};
const requestReadQuery = async (
  query: DocumentNode,
  variables: any,
  errorKey: string,
  dataKey: string,
  client: ApolloClient<any>,
) => {
  try {
    const data = await client.readQuery({
      query,
      variables,
    });
    if (data?.error || !data[dataKey]) throw Error(data.error);
    return data[dataKey];
  } catch (error: any) {
    throw Error(OFFER_BY_MODEL_ERRORS[errorKey] + error.message);
  }
};
export const getOffersByModel = async (
  client: ApolloClient<any>,
  config?: ModuleTrimConfiguration,
) => {
  if (!config) throw Error(OFFER_BY_MODEL_ERRORS.NO_CONFIG);
  const { carlineId = '', dealer, carlineYear = '', trimName = "" } = config;

  if (!dealer) throw Error(OFFER_BY_MODEL_ERRORS.NO_DEALER);
  const {postalcode: zipCode} =  dealer;

  let modelConfigByCarline = undefined;
  let offers: Offer[];
  let dealers: DealerModel[] = [];
  modelConfigByCarline = await requestReadQuery(
    MODELS_CONFIG,
    { id: carlineId },
    'MODELS_CONFIG',
    'modelByCarlineId',
    client,
  );
  if (!modelConfigByCarline)
    throw Error(OFFER_BY_MODEL_ERRORS.NO_CARLINE_CONFIG + carlineId);
  let requestOffers = await requestQuery(
    PRODUCT_OFFER_QUERY,
    { zipCode: zipCode },
    'OFFERS_DATA',
    'data',
    client,
  );
  offers = requestOffers.getSpecialOffersByZipCode || [];
  const { offerModelKey} = modelConfigByCarline;
  const {} = config;
  let offersByModel = getAllOffersByModelYear(
    offerModelKey,
    carlineYear,
    trimName,
    offers,
  );
  if (!offersByModel.length) {
    let requestOffers = await requestQuery(
      PRODUCT_NATIONAL_OFFER_QUERY,
      { modelName: "all" },
      'NO_NATIONAL_OFFERS',
      'data',
      client,
    );
    offersByModel = requestOffers.getNationalOffersByModel;
    offersByModel = getAllOffersByNationalModel(offerModelKey,trimName, offers);
  }
  modelConfiguration(modelConfigByCarline);
  return {
    modelConfig: modelConfigByCarline,
    offers: offersByModel.slice(undefined, DISPLAYED_OFFERS),
    dealer,
    dealers,
  };
};

export const offerViewMoreOffersStyle: TextCustomProps = {
  style: {
    fontSize: TextSize.TextSize14,
    textAlign: TextAlignment.left,
    lineHeight: TextLineHeight.TextLineHeight16,
    color: TextColor.inherit,
    fontType: TextType.Copy,
    fontWeight: TextWeight.regular,
  },
  tag: TextTag.span,
};