import React, { useState } from 'react';

import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

import { BottomContainerSection } from './bottom-container-section/BottomContainerSection';
import { FilterContainerSection } from './filter-container-section';
import {
  LayoutWrapper,
  OverflowWrapper,
  StyledBottomContainer,
  StyledOffersContainer,
  StyledOffersSectionWrapper,
  StyledOffersTitle,
} from './styled';
import { StickyHeader } from '../../components/sticky-header';
import { useTranslations } from '../../hooks/use-translations';

export const OffersSection = () => {
  const offersTitle = useTranslations().offersTitle;
  const [stickyHeaderToggle, setStickyHeaderToggle] = useState<boolean>(false);

  const handleChangeHeaderToggle = (value: boolean) => {
    setStickyHeaderToggle(value);
  };

  return (
    <OverflowWrapper>
      <LayoutWrapper>
        <StyledOffersSectionWrapper>
          <StyledOffersContainer>
            <StyledOffersTitle>
              <TextCustom
                appearance={TextAppearance.headline100}
                tag={TextTag.h1}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize32,
                    lineHeight: TextLineHeight.TextLineHeight36,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.light,
                  },
                }}
              >
                {offersTitle}
              </TextCustom>
            </StyledOffersTitle>
            <StickyHeader noPadding onChangeToggle={handleChangeHeaderToggle}>
              <FilterContainerSection />
            </StickyHeader>
            <StyledBottomContainer stickyHeaderToggle={stickyHeaderToggle}>
              <BottomContainerSection />
            </StyledBottomContainer>
          </StyledOffersContainer>
        </StyledOffersSectionWrapper>
      </LayoutWrapper>
    </OverflowWrapper>
  );
};
