import { Disclaimer, DisclaimerModal } from '@vw-marketing/us-components';
import React from 'react';
import ModalDisclaimerWrapper from '../../components/modal-disclaimer-wrapper';
import WSEBanner from '../../components/wse-banner';
import { useDisclaimerConfiguration } from '../../hooks/use-disclaimer-configuration';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { DisclaimerKeys, getDisclaimersByPage } from '../../utils/disclaimer';
import { OffersSection } from '../offers-section';
import {
  StyledImageContainer,
  StyledLandingWrapper,
  StyledMastheadContainer,
} from './styled';

export const Landing: React.FC = (): JSX.Element => {
  const { wseBannerConfig } = useFeatureAppConfig();
  const wseBanner = wseBannerConfig?.landingPage;
  const { disclaimers } = useDisclaimerConfiguration();
 
  const landingDisclaimers = getDisclaimersByPage(
    DisclaimerKeys.LandingKey,
    disclaimers,
  );

  return (
    <StyledLandingWrapper>
      {wseBanner?.enabled ? (
        <StyledMastheadContainer>
          <StyledImageContainer>
            <WSEBanner wseBanner={wseBanner} />
          </StyledImageContainer>
        </StyledMastheadContainer>
      ) : (
        <></>
      )}
      <OffersSection />
      <ModalDisclaimerWrapper>
        <DisclaimerModal disclaimers={landingDisclaimers as Disclaimer[]} />
      </ModalDisclaimerWrapper>
    </StyledLandingWrapper>
  );
};

export default Landing;
