import React from 'react';

import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

import { Offer } from '../../typing/offer';
import {
  StyledBlackContainer,
  StyledInformationOfferContainer,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferTypeLabelWrapper,
} from './styled';
import TrimModuleOfferType from '../trim-module-offer-type';
import { ModelTag } from '../../hooks-store/typings/incentive-store';

interface OfferDetailsCardProps {
  readonly modelKeyString?: string;
  readonly modelTag?: ModelTag;
  readonly modelOffersIDs?: number[];
  readonly offer: Offer;
}

export const OfferDetailsCard: React.FC<OfferDetailsCardProps> = ({
  modelTag,
  modelOffersIDs = [],
  offer,
}): JSX.Element | null => {
  const isSalesEventOffer: boolean = modelOffersIDs.includes(offer.dealId) ?? false;
  
  return (
    <StyledOfferCardWrapper className="offer-details_container">
      {isSalesEventOffer && (
        <StyledOfferTypeLabelWrapper className="offer-details_type-wrapper">
          <StyledBlackContainer>
            <TrimModuleOfferType modelTag={modelTag} />
          </StyledBlackContainer>
        </StyledOfferTypeLabelWrapper>
      )}
      <StyledInformationOfferContainer className="offer-details_deal-details">
        <StyledOfferMicroText>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h2}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                color: TextColor.primary,
                fontSize: TextSize.TextSize24,
                lineHeight: TextLineHeight.TextLineHeight28,
              },
            }}
          >
            {offer?.offerTitle}
          </TextCustom>

          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.p}
            style={{
              [Breakpoints.default]: {
                color: TextColor.primary,
                fontSize: TextSize.TextSize14,
                lineHeight: TextLineHeight.TextLineHeight20,
                fontWeight: TextWeight.regular,
              },
            }}
          >
            {offer?.offerSubtitle}
          </TextCustom>

          {offer?.dealCopy && (
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  fontWeight: TextWeight.regular,
                  color: TextColor.inherit,
                },
              }}
            >
              {offer?.offerCopy}
            </TextCustom>
          )}
          {offer?.dealLegal && (
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  fontWeight: TextWeight.regular,
                  color: TextColor.inherit,
                },
              }}
            >
              {offer?.offerLegal}
            </TextCustom>
          )}
        </StyledOfferMicroText>
      </StyledInformationOfferContainer>
    </StyledOfferCardWrapper>
  );
};
