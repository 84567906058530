import { History } from 'history';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { SalesEventOffersIds, SpecialEventConfig } from '../../..';

import { AppConstants } from '../../../utils/app-constants';
import {
  AttributeModel,
  FilterAttributeModel,
  OfferInformation,
  OffersModel,
  TypeMap,
} from '../../typings/incentive-store';
import { urlToFilters } from './query';
import { newLocalOfferType, OfferTypeModel } from '../../typings/offer-type';

/**
 * Build an array to show the filters options in the filter sidebar
 * @param offersData all the offers form the feed
 */
export const buildFilterAttributes = (
  offersData: TypeMap<OfferInformation>,
  specialEvent?: SpecialEventConfig,
): FilterAttributeModel[] => {
  const modelYears = getModelYears(offersData);
  const allModels = getModels(offersData);
  return [
    {
      name: 'Offers',
      filterKey: 'offers',
      attributes: sortBy(
        getOffersAttributes(newLocalOfferType, 'offers', specialEvent),
        'text',
      ),
    },
    {
      name: 'Model Year',
      filterKey: 'year',
      attributes: sortBy(modelYears, 'text'),
    },
    {
      name: 'Model',
      filterKey: 'model',
      attributes: sortBy(allModels, 'text'),
    },
  ];
};

/**
 * Format for the model attributes
 * @param offersData all offers from feed
 * @param filterKey Category key for models
 */
export const getModelAttributes = (
  offersData: TypeMap<OfferInformation>,
  filterKey: string,
): AttributeModel[] => {
  const modelOfferMapKeys = Object.keys(offersData);

  return modelOfferMapKeys.map((key: string) => ({
    filterKey,
    text: offersData[key].modelDisplayName,
    value: offersData[key].modelDisplayName.toLocaleLowerCase(),
  }));
};

/**
 * Format for the models
 * @param offersData all offers from feed
 */
export const getModels = (
  offersData: TypeMap<OfferInformation>,
): AttributeModel[] => {
  const uniqueModels = new Set<string>();
  for (const item of Object.values(offersData)) {
    uniqueModels.add(item.modelDisplayName);
  }

  return Array.from(uniqueModels).map(modelName => ({
    filterKey: 'model',
    text: modelName,
    value: modelName.toLocaleLowerCase(),
  }));
};

/**
 * Format for the model years
 * @param offersData all offers from feed
 */
export const getModelYears = (
  offersData: TypeMap<OfferInformation>,
): AttributeModel[] => {
  const uniqueYears = new Set<string>();
  for (const item of Object.values(offersData)) {
    item.offers.forEach((offer: any) => {
      offer.dealCarYear.forEach((year: string) => uniqueYears.add(year));
    });
  }

  return Array.from(uniqueYears).map(year => ({
    filterKey: 'year',
    text: year,
    value: year.toLocaleLowerCase(),
  }));
};

/**
 * Format for the offer type attributes
 * @param offerTypeAttr Offer type form local configuration
 * @param filterKey Category key for offer type
 */
export const getOffersAttributes = (
  offerTypeAttr: OfferTypeModel[],
  filterKey: string,
  specialEvent?: SpecialEventConfig,
): AttributeModel[] => {
  const attributes = offerTypeAttr.map((attr: OfferTypeModel) => ({
    filterKey,
    text: attr.offerName,
    value: attr.offerType,
    isSpecialEvent: false,
  }));
  if (specialEvent?.filterOptions?.length) {
    specialEvent?.filterOptions.map(({ text = '', value = '', isActive }) => {
      if (isActive) {
        attributes.push({
          text,
          value,
          filterKey: 'special-event',
          isSpecialEvent: true,
        });
      }
    });
  }
  return attributes;
};

/**
 * Get the offer type category
 * @param offerTypeKey Is the offer type name
 */
export const getOfferType = (offerType: string) => {
  const replacements: any = {
    apr: AppConstants.OfferTypeAPR,
    lease: AppConstants.OfferTypeLease,
  };
  if (replacements.hasOwnProperty(offerType)) {
    return replacements[offerType];
  }

  return AppConstants.OfferTypeOther;
};

/**
 * Get a list of offers filtered by the currently filters selected
 * @param modelOfferMap All offers from feed
 * @param appliedFilters Current apply filters
 */
export const filterOffers = (
  modelOfferMap: TypeMap<OfferInformation>,
  appliedFilters: AttributeModel[],
  salesEventOffersIds: SalesEventOffersIds | undefined,
): OfferInformation[] => {
  // Create an array of offers without the model key
  const modelsOffers = Object.keys(modelOfferMap).map(
    modelKey => modelOfferMap[modelKey],
  );

  // if there is not applied filters return model offers
  if (appliedFilters.length === 0) {
    return modelsOffers;
  }

  // Create a hash with the filterKey (filterKey: [value, value])
  const filters = appliedFilters.reduce(
    (next: any, { filterKey, value }: any) => ({
      ...next,
      [filterKey]: [...(next[filterKey] || []), value.toLowerCase()],
    }),
    {},
  );
  // lodash get function to get an array of values
  const dealCarModelName = get(filters, 'model', []).map((item: any) =>
    item.toLowerCase(),
  );
  // lodash get function to get an array of values
  const dealOfferTypes = get(filters, 'offers', []).map((item: any) =>
    item.toLowerCase(),
  );

  let specialEvents = get(filters, 'special-event', []).map((item: any) =>
    item.toLowerCase(),
  );

  const modelyear = get(filters, 'year', []).map((item: any) =>
    item.toLowerCase(),
  );

  const trimName = get(filters, 'trim', []).map((item: any) =>
    item.toLowerCase(),
  );

  let filteredOffers = modelsOffers;

  // model filter selected
  filteredOffers = filteredOffers.filter(model => {
    if (dealCarModelName.length) {
      return dealCarModelName.includes(model.modelDisplayName.toLowerCase());
    }
    return true;
  });

  // Apply special events filter if no model filter is applied
  if (specialEvents.length && !dealCarModelName.length) {
    filteredOffers = filteredOffers.filter(model =>
      specialEvents.includes(model.modelTag?.label.toLocaleLowerCase()),
    );
  }

  // Apply model year filter
  if (modelyear.length) {
    filteredOffers = filteredOffers
      .map(model => {
        const matchingOffers = model.offers.filter(offer =>
          offer.dealCarYear.some(year => modelyear.includes(year)),
        );
        return matchingOffers.length > 0
          ? {
              ...model,
              offers: matchingOffers,
              offerCount: matchingOffers.length,
            }
          : null;
      })
      .filter(model => model !== null) as OfferInformation[];
  }

  // Apply trim filter
  if (trimName.length) {
    filteredOffers = filteredOffers
      .map(model => {
        const matchingOffers = model.offers.filter(offer =>
          offer.dealCarTrimName.some(trim =>
            [trimName[0], ''].includes(trim.toLowerCase()),
          ),
        );
        return matchingOffers.length > 0
          ? {
              ...model,
              offers: matchingOffers,
              offerCount: matchingOffers.length,
            }
          : null;
      })
      .filter(model => model !== null) as OfferInformation[];
  }

  // Apply offer type filter
  filteredOffers = filteredOffers.map(model => {
    let offers = model.offers;
    let isModelEvent = false;
    let hasSpecialEvent = specialEvents.length;

    if (hasSpecialEvent) {
      isModelEvent = specialEvents.includes(
        model.modelTag?.label.toLocaleLowerCase(),
      );
    }

    offers = hasSpecialEvent
      ? isModelEvent && offers.length
        ? offers.filter(
            (offer: OffersModel) =>
              salesEventOffersIds &&
              salesEventOffersIds[offer.dealCarModel[0] || ''].includes(
                offer.dealId,
              ),
          )
        : []
      : offers;
    if (dealOfferTypes.length) {
      offers = offers.filter((offer: OffersModel) =>
        dealOfferTypes.includes(
          getOfferType(offer.dealOfferTypes[0].toLocaleLowerCase()),
        ),
      );
    }

    return {
      ...model,
      offerCount: offers.length,
      offers,
    };
  });

  // If no offers are found after filtering
  if (dealCarModelName.length && filteredOffers.length === 0) {
    return dealCarModelName
      .map(modelName => {
        const model = modelsOffers.find(
          m => m.modelDisplayName.toLowerCase() === modelName,
        );
        return model
          ? {
              ...model,
              imageData: {
                modelImage: model.imageData.modelImage,
                modelImageAlt: model.imageData.modelAltImage,
                modelYear: model.imageData.modelYear,
                background: model.imageData.background,
                isNewImage: model.imageData.isNewImage,
              },
              modelDisplayName: model.modelDisplayName,
              modelKey: model.modelKey,
              offerCount: 0,
              offers: [],
              slug: model.slug,
            }
          : null;
      })
      .filter(Boolean) as OfferInformation[];
  }

  return filteredOffers;
};

/**
 * Clear the filteredOffers to the initial state
 * @param modelOfferMap The list of vehicles filtered by slug
 */
export const clearFilters = (modelOfferMap: TypeMap<OfferInformation>) => {
  const filteredOffers = filterOffers(modelOfferMap, [], undefined);
  const offersAmount = filteredOffers.reduce(
    (next: number, { offerCount }: OfferInformation) => next + offerCount,
    0,
  );

  return {
    appliedFilters: [],
    filteredOffers,
    offersAmount,
  };
};

/**
 * Add model key to applied filter
 */
const addModelKeyFilter = (modelKey: string) => {
  return {
    filterKey: 'model',
    isSpecialEvent: false,
    text: modelKey,
    value: modelKey,
  };
};

/**
 * Add trim to applied filter
 */
const addTrimFilter = (trim: string) => {
  return {
    filterKey: 'trim',
    isSpecialEvent: false,
    text: trim.toLowerCase(),
    value: trim.toLowerCase(),
  };
};

/**
 * Set the filters to the store
 * @param history Router history
 * @param offersData Special obj data
 */
export const setFilterInformation = (
  history: History,
  offersData: TypeMap<OfferInformation>,
  salesEventOffersIds: SalesEventOffersIds | undefined,
  specialEvent?: SpecialEventConfig,
  modelKey?: string,
  trim?: string,
  isTrimLevel?: boolean,
) => {
  // Give format to the filterAttributes to be displayed on the sidebar
  const filterAttributes = buildFilterAttributes(
    offersData,
    specialEvent,
  );

  // Set appliedFilters with the URL filters
  let appliedFilters = urlToFilters(history, specialEvent);

  if (modelKey) {
    const appliedFiltersWithModelKey = addModelKeyFilter(modelKey);
    appliedFilters.push(appliedFiltersWithModelKey);
  }

  if (trim && isTrimLevel) {
    const appliedFiltersWithTrim = addTrimFilter(trim);
    appliedFilters.push(appliedFiltersWithTrim);
  }

  const filteredOffers = filterOffers(
    offersData,
    appliedFilters,
    salesEventOffersIds,
  );
  const offersAmount = filteredOffers.reduce(
    (next: number, { offerCount }: OfferInformation) => next + offerCount,
    0,
  );

  return {
    filterAttributes,
    appliedFilters,
    filteredOffers,
    offersAmount,
  };
};
