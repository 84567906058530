export const translations = {
  en: {
    offersTitle: 'Current Offers',
    defaultLegalDisclaimer:
      'By clicking "Submit", you agree to Volkswagen’s [Terms of Service](/en/terms.html) and [Privacy Statement](/en/privacy.html). In addition, by clicking "Submit", you also agree and consent to receive marketing communications regarding Volkswagen products and services from the Volkswagen authorized dealer you have selected, including by calling or texting you at the phone number(s) you’ve provided. If you have not selected a dealer, you agree and consent to receive marketing communications regarding Volkswagen products and services from the Volkswagen dealer associated with your zip code, including by calling or texting you at the phone number(s) you’ve provided. You understand these calls or text may be made using an automatic telephone dialing system and/or prerecorded message. Your consent to receive calls or texts is not required as a condition of any purchase or lease.',
    errors: {
      defaultErrorMessage: 'Something Went Wrong',
    },
    filtersSection: {
      clearAll: 'Reset All Filters',
      filteredByLabel: 'Filtered by',
      deselectAll: 'Deselect All',
      viewOffers: 'View Offers',
      filtersLabels: {
        modelYear: 'Model Year',
        model: 'Model',
        categories: {
          suvs: 'SUVs',
          sedans: 'Sedans',
          compacts: 'Compacts',
          evs: 'EVs',
          bus: 'Bus'
        },
      },
      label: 'Filters',
      zipcodePlaceholder: 'Enter ZIP Code',
      inputZipError: 'Invalid ZIP code',
      noDealersError: 'No dealers in this area',
    },
    modals: {
      modalHandOffNoBuying: {
        title: 'Shop online and find the vehicle you want.',
        dealerStepCard: {
          title: 'Select vehicle from dealer inventory.',
          msrp: 'Total MSRP',
          localDealer: 'Your selected vehicle and local dealership.',
          distance: 'mi away',
        },
        steps: [
          {
            title:
              'View real photos, pricing and details of the Volkswagen near you.',
            descriptionMarkdown:
              '<p>Your next Volkswagen is unique and your local dealer has the best information on what makes it special, including details on payments, incentives and special offers.</p> ',
            number: 2,
            icon: 'car-document',
            id: 'car-document-step',
          },
          {
            title: 'Choose how you want to move forward:click, call or come by.',
            descriptionMarkdown:
              '<p>Your name, address and some basic information will help the dealer start to build a relationship with you.</p> <p>It’s normal to have questions when considering buying a car. From specifics about trim and features, to a conversation about the dealer’s use of technology, feel free to click to submit your information, call for discussion, or come to the physical dealership. </p> ',
            number: 3,
            icon: 'chat',
            id: 'chat-step',
          },
          {
            title: 'Schedule time with the Dealer to check out the vehicle.',
            descriptionMarkdown:
              '<p>Experiencing the vehicle is an important part of the process. The Dealer will also explain available offsite test drive programs and contactless pickup options (if available).</p> ',
            number: 4,
            icon: 'contact-dealer',
            id: 'schedule-step',
          },
        ],
        dealerWebSite: "Go to the dealer's site",
        dealerWebSiteModified: 'Build my deal',
      },
      modalHandOffOnlineBuying: {
        title: 'Continue building your deal on the dealer\'s website',
        subtitle: "What to expect:",
        dealerStepCard: {
          title: '<p>You selected the **$$modelName$$** from:</p>',
          msrp: 'Total MSRP',
          localDealer: 'Your selected vehicle and local dealership.',
          distance: 'mi away',
        },
        steps: [
          {
            title:
              '',
            descriptionMarkdown:
              '<p>You can explore pricing, payment and lease finance options all online.</p> ',
            number: 2,
            icon: 'calculator',
            id: 'car-exploring-step',
          },
          {
            title: '',
            descriptionMarkdown:
              '<p>Then schedule a time online with the dealer to confirm and complete your purchase. </p> ',
            number: 3,
            icon: 'contact-dealer',
            id: 'chat-step',
          },
        ],
        dealerWebSite: "Build my deal >",
        dealerWebSiteModified: 'Build my deal',
      },
    },
    offersSection: {
      filter: {
        filterArea: {
          clearAll: 'Clear all',
        },
        filterSidebar: {
          filter: 'Filter',
          clearAll: 'Clear All',
          showAll: 'Show all results',
        },
      },
      filterToolbar: {
        showing: 'Showing',
      },
      infoContent: {
        showing: 'Showing matches at:',
      },
      leftContainerSection: {
        breadcrumbLabel: 'Back to Dealers Page',
        inputZipPlaceholder: 'Enter ZIP Code',
        inputZipLabel: 'Your current location',
        inputZipError: 'Invalid ZIP code',
        noDealersError: 'No dealers in this area',
      },
      rightContainerSection: {
        noDealers: 'Sorry, there are no dealers in this area.',
        anotherZip: 'Please try to enter another ZIP code.',
      },
      modelOfferSection: {
        modelOfferHead: {
          noResults: {
            noOffers:
              'Sorry, there are currently no offers available for this model.',
            contactOrQuote:
              'Contact your dealer or get a quote on this vehicle.',
            getAQuote: 'Get a Quote',
            contactDealer: 'Contact Dealer',
          },
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
          buildAndPrice: 'Build and Price',
          startingMsrp: 'Starting MSRP $',
          totalMsrp: 'Total MSRP $',
          viewVehicle: 'View vehicle',
          buildMyDeal: 'Build my deal',
        },
        offerCard: {
          offerDetails: 'View offer details',
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
          getCertificate: 'Get Certificate',
          inventoryRedirect: 'View Inventory',
        },
      },
    },
    tier1: {
      landing: {
        label1: 'Current',
        label2: 'offers',
      },
    },
    tier1simplifiedVersion: {
      localOffers: {
        offerCard: {
          offerDetails: 'View offer details',
          getAQuote: 'Get a Quote',
          contactDealer: 'Contact Dealer',
        },
        offersBtnLabel: 'View all',
        offersBtnTitle: 'Your Local Offers',
        noResultsHeader: 'Sorry, there are no offers available at this time.',
        noResultsSubHeader:
          'Contact your local dealer or visit their website to get more information.',
        noResultsBtnLabel1: 'Visit Site',
        noResultsBtnLabel2: 'Contact Dealer',
      },
      inputZipPlaceholder: 'Enter ZIP Code',
      inputZipLabel: 'Your current location',
      inputZipError: 'Invalid ZIP code',
    },
  },
};
