import { History } from 'history';
import { SalesEventOffersIds, SpecialEventConfig } from '../../..';

import { ErrorHandlingModel } from '../../../store/fetch/models-config';
import {
  AttributeModel,
  DealerModel,
  ModalZipDataModel,
  ModelTag,
  OfferInformation,
  OffersModel,
  TypeMap,
} from '../../typings/incentive-store';
import { StoreModel } from '../../typings/store';
import IncentiveConstants from './incentive-constants';

import { ModelConfig } from '../../../typing/model-config';
import { SharedConfigurationsResult } from '../../../typing/shared-configs';

/**
 * Declaration of all the possible actions that InsentiveStore is going to handle
 * @param dispatch function that call reducer
 */
const getIncentiveActions = (dispatch: any) => ({
  // Main data
  // tslint:disable-next-line:typedef
  [IncentiveConstants.CloneStoreData](
    initialSatate: StoreModel,
    history: History,
    specialEvent?: SpecialEventConfig,
    salesEventOffersIds?: SalesEventOffersIds,
    modelKey?: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) {
    return dispatch(IncentiveConstants.CloneStoreData, {
      initialSatate,
      history,
      specialEvent,
      salesEventOffersIds,
      modelKey,
      trim,
      isTrimLevel,
    });
  },
  [IncentiveConstants.SetSharedConfigs](
    fetchedSharedConfigs: SharedConfigurationsResult,
  ) {
    return dispatch(IncentiveConstants.SetSharedConfigs, {
      fetchedSharedConfigs,
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetInitialData](
    pageName: string,
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel,
    history: History,
    zip: string,
    ipZip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    specialEvent?: SpecialEventConfig,
    salesEventOffersIds?: SalesEventOffersIds,
    modelKey?: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) {
    return dispatch(IncentiveConstants.SetInitialData, {
      pageName,
      offersData,
      dealers,
      dealer,
      history,
      zip,
      ipZip,
      errorHandling,
      modelsConfig,
      specialEvent,
      salesEventOffersIds,
      modelKey,
      trim,
      isTrimLevel,
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateStoreByZip](
    offersData: TypeMap<OfferInformation>,
    dealers: DealerModel[],
    dealer: DealerModel,
    history: History,
    zip: string,
    errorHandling: ErrorHandlingModel[],
    modelsConfig: ModelConfig | undefined,
    offerId?: number,
    specialEvent?: SpecialEventConfig,
    salesEventOffersIds?: SalesEventOffersIds,
    modelKey?: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) {
    return dispatch(IncentiveConstants.UpdateStoreByZip, {
      offersData,
      dealers,
      dealer,
      history,
      zip,
      errorHandling,
      modelsConfig,
      offerId,
      specialEvent,
      salesEventOffersIds,
      modelKey,
      trim,
      isTrimLevel,
    });
  },

  // Filters functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateAppliedFilter](
    filter: AttributeModel,
    history: History,
    pendingProcessState: boolean,
    salesEventOffersIds: SalesEventOffersIds,
  ) {
    return dispatch(IncentiveConstants.UpdateAppliedFilter, {
      filter,
      history,
      pendingProcessState,
      salesEventOffersIds,
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.ClearFilters](
    history: History,
    pendingProcessState: boolean,
  ) {
    return dispatch(IncentiveConstants.ClearFilters, {
      history,
      pendingProcessState,
    });
  },

  // Modal functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.ActivateMobileFilterModal](
    mobileModalFilterActivated: boolean,
  ) {
    return dispatch(
      IncentiveConstants.ActivateMobileFilterModal,
      mobileModalFilterActivated,
    );
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.CloseContactDealerForm]() {
    return dispatch(IncentiveConstants.CloseContactDealerForm);
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetModalFormData](
    offer: OffersModel,
    modelTag: ModelTag,
    dealer: DealerModel,
    formType: string,
    formName: string,
  ) {
    return dispatch(IncentiveConstants.SetModalFormData, {
      offer,
      modelTag,
      dealer,
      formType,
      formName,
    });
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetDRModalData](active: boolean) {
    return dispatch(IncentiveConstants.SetDRModalData, active);
  },
  // tslint:disable-next-line:typedef
  [IncentiveConstants.ResetStore](history: History) {
    return dispatch(IncentiveConstants.ResetStore, history);
  },
  // Modal zip functionality
  // tslint:disable-next-line:typedef
  [IncentiveConstants.UpdateModalZipState](modalZipState: ModalZipDataModel) {
    return dispatch(IncentiveConstants.UpdateModalZipState, modalZipState);
  },

  // General Functions
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetPendingProcessState](pendingProcessState: boolean) {
    return dispatch(
      IncentiveConstants.SetPendingProcessState,
      pendingProcessState,
    );
  },
  // Modal initial data
  // tslint:disable-next-line:typedef
  [IncentiveConstants.SetModalInitialData](
    modelKey: string,
    trim?: string,
    isTrimLevel?: boolean,
  ) {
    return dispatch(
      IncentiveConstants.SetModalInitialData,
      modelKey,
      trim,
      isTrimLevel,
    );
  },
});

export default getIncentiveActions;
