import { localModelOrder } from '../config/model-order';
import {
  DealerModel,
  IncentiveStoreModel,
  OfferInformation,
  OffersModel,
} from '../hooks-store/typings/incentive-store';
import { CarModelConfig } from '../typing/main';

import { Offer } from '../typing/offer';

export function getAppliedFilterList(store: any, event: any) {
  const filterList: any[] = [
    {
      type: 'single_value',
      name: 'ZIP',
      values: [store.zip],
    },
  ];

  return {
    ...event,
    filterlist: filterList,
  };
}

export function getResultsFilterData(offers: Offer[], event: any) {
  const filterResults: any[] = [];
  offers.map((offer: Offer) => {
    filterResults.push({
      campaignId: `${offer.dealId}`,
    });
  });

  return {
    ...event,
    results: filterResults,
    numberOfResults: offers?.length,
  };
}

export function getResultsFilterDataStore(
  store: IncentiveStoreModel,
  event: any,
) {
  const filterResults: any[] = [];
  let offersCount: number = 0;
  store?.filteredOffers?.map((offer: OfferInformation) => {
    offer.offers.map((offer: OffersModel) => {
      filterResults.push({
        campaignId: `${offer.dealId}`,
      });
    });
    offersCount += offer.offers.length;
  });

  return {
    ...event,
    results: filterResults,
    numberOfResults: offersCount,
  };
}

export function getDealerData(dealer?: DealerModel, eventObject?: any) {
  return {
    ...eventObject,
    informationBnr: dealer?.dealerid,
    informationName: dealer?.name,
    informationDistance: dealer?.distance,
    informationZIP: dealer?.postalcode,
  };
}

export function getConfigurationData(
  offer?: Offer,
  model?: CarModelConfig,
  event?: any,
) {
  return {
    ...event,
    CarlineName:
      (offer?.dealCarModelName?.length && offer?.dealCarModelName[0]) ||
      model?.name ||
      undefined,
    CarlineId: model?.carlineId,
    SalesgroupName:
      (offer?.dealCarTrim?.length && offer?.dealCarTrim[0]) || undefined,
    PriceCampaignID: [offer?.dealId ? `${offer?.dealId}` : undefined],
    PriceCampaignDescription: [offer?.dealTitle],
    PriceCampaignValue: offer?.dealPricePerMonth
      ? [offer?.dealPricePerMonth]
      : undefined,
  };
}

export function getConfigurationDataOffers(offer?: OffersModel, event?: any) {
  let carlineIdObj = localModelOrder.find(
    el => el.modelKey === offer?.modelKey,
  );

  return {
    ...event,
    CarlineName:
      (offer?.dealCarModelName?.length && offer?.dealCarModelName[0]) ||
      carlineIdObj?.modelName ||
      undefined,
    CarlineId: carlineIdObj?.carlineId,
    SalesgroupName:
      (offer?.dealCarTrimName?.length && offer?.dealCarTrimName[0]) ||
      undefined,
    PriceCampaignID: [offer?.dealId ? `${offer?.dealId}` : undefined],
    PriceCampaignDescription: [offer?.dealTitle],
    PriceCampaignValue: offer?.dealPricePerMonth
      ? [offer?.dealPricePerMonth]
      : undefined,
  };
}
