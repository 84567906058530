import { styled, Breakpoints } from "@vw-marketing/us-components";

export const StyledSeparatorLine: any = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0;
  background-color: #dfe4e8;
  margin-bottom: 17px;

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 17px;

  }
`;

export const OffersBoxContainer: any = styled.div`
display: content;
`;

export const OffersCollapseContainer: any = styled.div`
&.visible {
    max-height: 100%;
    overflow: hidden;
    transition: all 0.2s ease-in;
}

&.hidden {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-out;
}

`;

// the icon needs to be moved down by the size of the wrapper's top padding plus the size of the select's top padding
export const StyledIcon: any = styled.span<any>`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-bottom: 2px;
  pointer-events: none;
  .animation svg {
    transition: transform 0.5s ease;
    transform: rotate(0deg);
  }
  .animation.visible svg {
    transform: rotate(180deg);
  }
`;

export const StyledLabel: any = styled.span<any>`
  display:flex;
  width: 100%;
  text-wrap: nowrap;
  white-space: nowrap;
`;

export const OffersBoxButton: any = styled.button`
border: none;
background:none;
cursor: pointer;
width: 100%;
padding: 0;
display: flex;
justify-content: space-between;
align-items: center;
color: #001E50;

&:hover, :focus {
    color: #0040C5;
}

`;