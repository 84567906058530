import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledLandingWrapper: any = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export const StyledImageContainer: any = styled.div`
  img {
    position: absolute;
    width: auto;
    height: 230px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: ${Breakpoints.b960}px) {
      position: inherit;
      width: 100%;
      height: auto;
      left: 0;
      top: 0;
      transform: none;
    }
  }
`;

export const StyledMastheadContainer: any = styled.div`
  width: 100%;
  height: 230px;
  position: relative;

  @media (min-width: ${Breakpoints.b960}px) {
    height: auto;
  }
`;
