import {
  CustomTextInput,
  DisableFocusWrapper,
  Magnifier,
  TextInputAppearance,
} from '@vw-marketing/us-components';
import axios from 'axios';
import { History } from 'history';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import { useTrackingManager } from '../../hooks/use-tracking-manager';

import IncentiveConstants from '../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../hooks-store/store';
import { IncentiveStoreModel } from '../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { getStringifiedServiceConfigsServiceConfig } from '../../utils/get-stringified-service-configs-service-config';

import { AppConstants } from '../../utils/app-constants';

import { updateStoreData } from '../../fetch-data';
import { useFeatureAppEnvironment } from '../../hooks/use-feature-app-environment';
import { useFeatureServices } from '../../hooks/use-feature-services';
import {
  onHandleInputTrackingEvent,
  onHandlerFilterSeachLoad,
  onHandleZipCodeErrorEvent,
} from '../../tagging/tagging-helpers';

interface InputZipProps {
  readonly placeholder?: string;
  readonly label?: string;
  readonly name?: string;
  readonly errorText?: string;
  readonly maxLength?: number;
  readonly altErrortext?: string;
  readonly autoFocus?: boolean;
  readonly isModal?: boolean;
  readonly offerId?: number | undefined;
  readonly modelKey?: string;
  readonly trim?: string;
  readonly isTrimLevel?: boolean;
}

interface RefProps {
  readonly applyZip: () => void;
}

/* eslint-disable-next-line react/display-name */
export const InputZipOffers = forwardRef<RefProps, InputZipProps>(
  (
    {
      placeholder,
      autoFocus,
      label,
      name,
      maxLength,
      errorText,
      altErrortext,
      isModal,
      offerId,
      modelKey,
      trim,
      isTrimLevel,
    },
    ref,
  ) => {
    
    // Call different services used to apply fetches and update the store data
    const { faServicesUrl, mockIds, mocksBaseUrl } = useFeatureAppConfig();
    const { baseUrl } = useFeatureAppEnvironment();
    const history = useHistory();
    const {
      'locale-service': localeService,
      'service-config-provider': serviceConfigProvider,
      's2:server-request': serverRequest,
      'zip-manager': zipManager,
    } = useFeatureServices();
    const {
      configs: { 'graphql-server': graphQLServer },
    } = serviceConfigProvider;
    const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
      serviceConfigProvider,
      serverRequest,
    );

    // App store
    const [
      store,
      {
        [IncentiveConstants.UpdateStoreByZip]: updateStoreByZip,
        [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
        [IncentiveConstants.UpdateModalZipState]: updateModalZipState,
      },
    ] = useStore();

    const {
      modelOrder,
      mappedTrimName,
      specialEventConfig,
      salesEventOffersIds,
    } = store.fetchedSharedConfigs!;

    // All the states for this Functional Component
    const [zipCode, setZipCode] = useState(
      store.modalZipState.zipTemp && store.modalZipState.zipError
        ? store.modalZipState.zipTemp
        : store.zip
        ? store.zip
        : '',
    );
    const [invalidZipcode, setInvalidZipcode] = useState(
      store.modalZipState.zipError ? true : false,
    );

    const trackingManager = useTrackingManager();

    const successResultCallback = (updatedStore: IncentiveStoreModel) => {
      onHandlerFilterSeachLoad(trackingManager, updatedStore);
    };

    /**
     * Udate store data using the zip value
     */
    const updateStoreHandle = () => {
      const commonParams = {
        baseUrl: baseUrl as string,
        zip: zipCode,
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        zipManager,
        history: history as History,
        faServicesUrl: faServicesUrl ?? '',
        mockIds,
        mocksBaseUrl,
        mappedTrimName,
        successResultCallback,
        offerId,
      };

      updateStoreData({
        ...commonParams,
        updateStoreByZip,
        modelOrder,
        specialEvent: specialEventConfig,
        graphQLServer,
        modelKey,
        trim,
        isTrimLevel,
        salesEventOffersIds,
      });
    };

    /**
     * Manage process to validate the zip code
     */
    const updateZip = () => {
      updateModalZipState({
        activeZipModal: false,
        zipError: false,
      });
      setPendingProcessState(true);
      zipManager.setZipModalActive(false);
      //updateStoreHandle();
      // onHandleInputTrackingEvent(isModal ? 'Search Layer' : 'Search Input', zipCode, trackingManager);
      zipManager.setZipCookie(zipCode); //we set it since is a valid zipcode according to google
      if (store.pageName !== AppConstants.Tier1SimplifiedVersion) {
        //We dont need to do the update here for the simplified version since we subscribe the updateStore
        // so it will be executed automatically after calling zipManager.setZipCookie(zipCode);
        updateStoreHandle();
      }
    };

    /**
     *  Manage validation to verify if the zip is valid
     * @param validZip If is a valid zip code
     */
    const validateZipChange = (validZip: boolean) => {
      onHandleInputTrackingEvent(
        isModal ? 'Search Layer' : 'Search Input',
        zipCode,
        trackingManager,
        store,
      );

      if (
        validZip &&
        (!store.modalZipState.zipError ||
          store.modalZipState.zipTemp !== zipCode)
      ) {
        if (zipCode !== store.zip || isModal) {
          if (isModal) {
            updateModalZipState({
              activeZipModal: false,
            });
            zipManager.setZipModalActive(false);
          }
          setPendingProcessState(true);
          axioZipValidation();
        } else {
          // zipManager.setZipCookie(zipCode);
          zipManager.setZipModalActive(false);
          updateModalZipState({
            activeZipModal: false,
            zipError: false,
          });
          // onHandleInputTrackingEvent('Search Input', store.zip, trackingManager, store);
          onHandlerFilterSeachLoad(trackingManager, store);
        }
      } else {
        setInvalidZipcode(true);
        onHandleZipCodeErrorEvent(
          trackingManager,
          { errorField: 'zipcode', errorFields: ['zipcode'] },
          { message: errorText, code: 'ZE01' },
        );
      }
    };

    /**
     * Validate the zip using an API
     */
    const axioZipValidation = () => {
      const country = new RegExp(AppConstants.PUERTO_RIC0_REGEX).test(zipCode)
        ? AppConstants.PUERTO_RICO_CODE
        : AppConstants.UNITED_STATES_CODE;

      axios({
        method: 'get',
        url: AppConstants.GoogleApiGeoCodeUrl.replace(
          '{postalCode}',
          zipCode,
        ).replace('{apiCountry}', country),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response: any) => {
          if (response.status === 200) {
            const data = response.data;
            if (data && data.results && data.results.length) {
              setInvalidZipcode(false);
              updateZip();
              return;
            } else {
              updateModalZipState({
                activeZipModal: isModal,
                zipError: true,
                zipTemp: zipCode,
              });
            }
          } else {
            console.log('ERROR:: Zip validation, status: ', response.status);
          }
          onHandleZipCodeErrorEvent(
            trackingManager,
            { errorField: 'zipcode', errorFields: ['zipcode'] },
            { message: errorText, code: 'ZE01' },
          );
        })
        .catch((error: Error) => {
          console.log('ERROR:: Zip validation: ', error);
          setInvalidZipcode(true);
          // onHandleInputTrackingEvent(isModal ? 'Search Layer':'Search Input', zipCode, trackingManager, store);
          onHandleZipCodeErrorEvent(
            trackingManager,
            { errorField: 'zipcode', errorFields: ['zipcode'] },
            { message: errorText, code: 'ZE01' },
          );
        });
    };

    /**
     * This implementation allows some parent component call function through references
     */
    useImperativeHandle(
      ref,
      (): RefProps => ({
        applyZip(): void {
          applyZipBtnAction();
        },
      }),
    );

    /**
     * Apply changes using an external button event
     */
    const applyZipBtnAction = () => {
      validateZipChange(new RegExp(AppConstants.VALID_ZIP_REGEX).test(zipCode));
    };

    /**
     * Listening to the onKeyPress event this function updates the store using the zip's information
     * @param event onKeyPress event
     */
    const changeZipPressedEnterKey = (
      event: React.KeyboardEvent<HTMLInputElement>,
    ): void => {
      const key = event.which || event.keyCode;

      if (key === 13) {
        validateZipChange(
          new RegExp(AppConstants.VALID_ZIP_REGEX).test(zipCode),
        );
      }
    };

    /**
     * Listening to the onChange event this function updates input state
     * @param event onChange event
     */
    const onChange = (event: React.SyntheticEvent<HTMLInputElement>): void => {
      const zipValue = event.currentTarget.value;

      if (new RegExp(AppConstants.VALID_ZIP_REGEX_ON_CHANGE).test(zipValue)) {
        setInvalidZipcode(false);
        setZipCode(zipValue);
      }
    };

    useEffect(() => {
      if (
        store.modalZipState.zipError &&
        zipCode !== store.modalZipState.zipTemp
      ) {
        setZipCode(store.modalZipState.zipTemp || '');
        setInvalidZipcode(true);
      } else if (zipCode !== store.zip) {
        setZipCode(
          (store.modalZipState.zipError
            ? store.modalZipState.zipTemp
            : store.zip) || '',
        );
      }

      if (store.modalZipState.zipError) {
        setInvalidZipcode(true);
      } else {
        setInvalidZipcode(false);
      }
    }, [store.zip, store.modalZipState.zipTemp]);

    useEffect(() => {
      if (!store.modalZipState.zipError && invalidZipcode) {
        setInvalidZipcode(false);
      } else if (store.modalZipState.zipError && !invalidZipcode) {
        setInvalidZipcode(true);
      }
    }, [store.modalZipState.zipError]);

    useEffect(() => {
      if (store.zipNoDealers) {
        setInvalidZipcode(false);
      }
    }, [store.zipNoDealers]);

    return (
      <DisableFocusWrapper>
        <CustomTextInput
          appearance={
            (errorText && invalidZipcode) ||
            (store.zipNoDealers && altErrortext)
              ? TextInputAppearance.Error
              : TextInputAppearance.Default
          }
          onChange={onChange}
          onKeyPress={changeZipPressedEnterKey}
          value={zipCode ? zipCode : ''}
          rightIcon={<Magnifier variant="default" />}
          rightIconColor="#001e50"
          rightIconBtnFun={applyZipBtnAction}
          placeholder={placeholder}
          autoFocus={autoFocus}
          label={label}
          useCustomZipText={false}
          name={name}
          maxLength={maxLength}
          errorText={
            (invalidZipcode && errorText) ||
            (store.zipNoDealers && altErrortext) ||
            ''
          }
          customTextColor="#001e50"
        />
      </DisableFocusWrapper>
    );
  },
);

export default InputZipOffers;
