import isBrowser from 'is-browser';
import React from 'react';

export function useUniversalEffect(
  effect: React.EffectCallback,
  deps?: React.DependencyList,
): void {
  React.useEffect(effect, deps);

  if (!isBrowser) {
    effect();
  }
}
