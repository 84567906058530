import React from 'react';

import { ModalDisplayMode } from '../../typing/modal-display';
import { ModalWithLayer } from '../modal-with-layer';
import { useTranslations } from '../../hooks/use-translations';

interface ModalDisplayFactoryProps {
  mode?: ModalDisplayMode;
  modalProps: any;
}

export const ModalDisplayFactory: React.FC<ModalDisplayFactoryProps> = ({
  mode,
  modalProps,
}) => {
  const translations = useTranslations().modals;

  switch (mode) {
    case ModalDisplayMode.TRIM_MODULE_DETAILS:
      return (
        <ModalWithLayer
          {...modalProps}
          translations={translations.trimModuleDetails}
        />
      );
    default:
      return <div>Error: UNKNOWN MODAL MODE</div>;
  }
};
