import React from 'react';

import {
  Close,
  Text,
  TextAppearance,
  TextColor,
  TextTag,
} from '@vw-marketing/us-components';
import { StyledIcon, StyledListItem } from './styled';
import { useStore } from '../../../../../hooks-store/store';

export const ListItem: React.FC<any> = (props: any): JSX.Element => {
  const {
    filter,
    updateAppliedFilter,
    setPendingProcessState,
    history,
  } = props;
  const [store] = useStore();
  const { salesEventOffersIds } = store.fetchedSharedConfigs;
  
  const updateFilter = () => {
    updateAppliedFilter(filter, history, true, salesEventOffersIds);
    setTimeout(() => {
      setPendingProcessState(true);
    }, 1000);
  };

  return (
    <StyledListItem onClick={updateFilter} className="notranslate">
      <Text
        appearance={TextAppearance.label100}
        tag={TextTag.span}
        color={TextColor.inherit}
      >
        {filter.text}
      </Text>
      <StyledIcon>
        <Close variant="small" />
      </StyledIcon>
    </StyledListItem>
  );
};
