import { LoaderSpinner } from '@vw-marketing/us-components';
import React, { useState } from 'react';
import { ErrorMessage } from '../components/error-message';
import { useSharedConfigs } from '../hooks/use-shared-configs';
import { DataServer } from '../store/data-server';
import {
  ModelsConfigResponse,
  fetchModelsConfigData,
} from '../store/fetch/models-config';
import { FeatureAppStandalone } from './standalone';
import { StyledAppContainer } from './styles';
import { useStore } from '../hooks-store/store';
interface AppProps {
  baseUrl?: string;
  done?: (result?: unknown) => void;
}

export const App: React.FC<AppProps> = ({ baseUrl, done }) => {
  const [store] = useStore();
  const [modelsConfigData, setModelsConfigData] = useState<
    ModelsConfigResponse | undefined
  >(undefined);
  const error = modelsConfigData?.errorHandling?.[0];
  const loading = !modelsConfigData;  
  
  loading && fetchModelsConfigData(setModelsConfigData);

  const loadingSharedConfigs = !store.fetchedSharedConfigs
  useSharedConfigs();
  
  return (
    <>
      {error && <ErrorMessage message1={error.errorMessage} />}
      {modelsConfigData?.fetchedModelsConfig && store.fetchedSharedConfigs && (
        <DataServer modelsConfig={modelsConfigData?.fetchedModelsConfig}>
          <StyledAppContainer className="special-offers-app-container">
            <FeatureAppStandalone baseUrl={baseUrl} done={done} />
          </StyledAppContainer>
        </DataServer>
      )}
      {loading && loadingSharedConfigs && <LoaderSpinner />}
    </>
  );
};
