import {
  TextAppearance,
  TextColor,
  TextCustom,
  TextWeight,
  styled,
} from '@vw-marketing/us-components';
import React from 'react';

const StyledNoOffersContainer = styled.div`
  color: #96A3A8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;
`;

interface TrimModuleNoOffersProps {
  message: string;
}
const TrimModuleNoOffers: React.FC<TrimModuleNoOffersProps> = ({ message }) => {
  return (
    <StyledNoOffersContainer>
      <TextCustom
        style={{ color: TextColor.inherit, fontWeight: TextWeight.bold}}
        appearance={TextAppearance.headline200}
      >{message}
      </TextCustom>
    </StyledNoOffersContainer>
  );
};

export default TrimModuleNoOffers;
