import React from 'react';
import { SpecialOffersTranslations } from '../typing/translations';
import { useInventory } from './use-inventory';

export const TranslationsContext = React.createContext<
  SpecialOffersTranslations | undefined
>(undefined);

export function useTranslations(): SpecialOffersTranslations {
  const inventoryTranlsations = useInventory().translations;
  const translations = {
    ...React.useContext(TranslationsContext),
    ...inventoryTranlsations,
  };

  if (!translations) {
    throw new Error('No translations context value was provided.');
  }

  return translations;
}
