import { Text, TextAppearance, TextColor } from '@vw-marketing/us-components';
import React from 'react';
import {
  DealerDetailIcon,
  DealerDetailText,
  DealerDetailWrapper,
  StyledIcon,
} from './styled';

interface DealerInformationDetailProps {
  Icon: any;
  label1?: string | JSX.Element;
  label2?: string | JSX.Element;
  textCustom?: JSX.Element;
}
const DealerInformationDetail = ({
  label1,
  label2,
  textCustom,
  Icon,
}: DealerInformationDetailProps): JSX.Element => (
  <DealerDetailWrapper>
    <DealerDetailIcon>
      <StyledIcon>
        <Icon iconSize={15} />
      </StyledIcon>
    </DealerDetailIcon>
    <DealerDetailText>
      <Text color={TextColor.inherit} appearance={TextAppearance.copy100}>
        {label1}
      </Text>
      <Text color={TextColor.inherit} appearance={TextAppearance.copy100}>
        {label2}
      </Text>
      {textCustom && <>{textCustom}</>}
    </DealerDetailText>
  </DealerDetailWrapper>
);

export default DealerInformationDetail;
