import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledSectionWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 70px;
`;

export const StyledHeaderContainer: any = styled.div<any>`
  display: flex;
  justify-content: flex-start;
  gap: 60px;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px #dfe4e8;

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 25px;
  }

  @media (max-width: ${Breakpoints.b560}px) {
    justify-content: space-between;
  }
`;

export const StyledCursorAction: any = styled.div`
  cursor: pointer;
`;
