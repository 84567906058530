import React from 'react';
import { IconProps } from './typing';

const PinPlace = ({ iconSize }: IconProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} viewBox="0 0 20 20">
        <path fill="currentColor" fillRule="nonzero" d="M10 .833c3.367 0 5.833 2.493 5.833 5.887 0 2.592-.905 5.44-2.384 8.203-1.264 2.36-2.667 4.244-3.449 4.244-.78 0-2.173-1.871-3.436-4.227-1.486-2.772-2.397-5.628-2.397-8.22C4.167 3.326 6.633.833 10 .833zM10 2.5c-2.443 0-4.167 1.743-4.167 4.22 0 2.274.832 4.882 2.2 7.433a21.71 21.71 0 0 0 1.649 2.63c.113.152.22.29.319.41.099-.121.208-.26.323-.415a21.637 21.637 0 0 0 1.655-2.641c1.361-2.542 2.188-5.142 2.188-7.417 0-2.477-1.724-4.22-4.167-4.22zm0 1.667c1.452 0 2.5 1.047 2.5 2.5 0 1.452-1.048 2.5-2.5 2.5s-2.5-1.048-2.5-2.5c0-1.453 1.048-2.5 2.5-2.5z" />
    </svg>

);

export default PinPlace;