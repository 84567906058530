import { CloseButton, styled } from '@vw-marketing/us-components';
import React, { ButtonHTMLAttributes } from 'react';

export interface CloseButtonProps {}

const StyledCloseButton = styled.div`
    width: 44px;
    position: fixed;
    right: 0;
    top: 0;
    margin: 18px;
    button {
      border-radius: 50%;
      &:focus {
        outline: none;
        background-color: rgb(0, 119, 204);
        color: rgb(255, 255, 255);
        box-shadow: rgb(0, 30, 80) 0px 0px 0px 1px inset;
        border-color: rgb(0, 30, 80);
      }
      &:hover:not(:focus) {
        background-color: rgba(194, 202, 207, 0.2);
      }
    }
`;
const ModalCloseButton: React.FC<ButtonHTMLAttributes<CloseButtonProps>> = (
  props,
) => {
  return (
    <StyledCloseButton className="close-button" {...props} >
      <CloseButton />
    </StyledCloseButton>
  );
};

export default ModalCloseButton;
