import React, { memo, useEffect, useState } from 'react';

import { Breakpoints, CustomSlider } from '@vw-marketing/us-components';

import { AppConstants } from '../../../../utils/app-constants';
import {
  DealerModel,
  ImageOfferData,
  OfferInformation,
  OffersModel,
} from '../../../../hooks-store/typings/incentive-store';
import { localModelOrder, ModelOrder } from '../../../../config/model-order';
import { NewOfferCard } from '../offer-card/NewOfferCard';
import { NewStyleCarouselContainer } from './styled';
import {
  onHandleCarouselLoaded,
  onSlideActionTracking,
} from '../../../../tagging/tagging-helpers';
import { transformIrisUrl } from '../../../../utils/general';
import { useFeatureAppConfig } from '../../../../hooks/use-feature-app-config';
import { useFeatureServices } from '../../../../hooks/use-feature-services';
import { useTrackingManager } from '../../../../hooks/use-tracking-manager';
import { useStore } from '../../../../hooks-store/store';

interface OfferCardSectionProps {
  readonly offerInformation: OfferInformation;
  readonly dealer?: DealerModel;
  readonly isTier1?: boolean;
}

export const NewOfferCardSection = memo(
  ({
    offerInformation,
    dealer,
    isTier1,
  }: OfferCardSectionProps): JSX.Element | null => {
    const [imageMap, setImageMap] = useState<ImageOfferData>(
      offerInformation.imageData,
    );
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const [store] = useStore();

    const trackingManager = useTrackingManager();
    const {
      faHostServerUrl = '',
      InventoryRedirectFAName,
      InventoryRedirectPageName,
      mode,
    } = useFeatureAppConfig();

    const { modelOrder } = store.fetchedSharedConfigs;

    const useModelOrder = modelOrder ?? localModelOrder;
    const { 'navigation-service': navigationService } = useFeatureServices();

    /**
     * Use the model name to create a slug
     */
    const getSlug = () => {
      const modelOrderEle: ModelOrder = useModelOrder.filter(
        (ele: ModelOrder) => ele.modelKey === offerInformation.modelKey,
      )[0];

      return modelOrderEle?.modelName?.toLowerCase().replace(/ /g, '-');
    };

    const navigateToInventory = navigationService.navigateTo(
      InventoryRedirectPageName
        ? InventoryRedirectPageName
        : AppConstants.InvRedirectPagename,
      {
        [InventoryRedirectFAName
          ? InventoryRedirectFAName
          : AppConstants.InvRedirectFAName]: `/inventory/results/${getSlug()}`,
      },
    );

    const navigateToInventoryHref = navigateToInventory?.createHref();

    useEffect(() => {
      if (offerInformation.imageData) {
        setImageMap(offerInformation.imageData);
      }
    }, [offerInformation.imageData]);

    // useEffect to get current width and height to handle
    // custom resolution 1280px x 483px in modal mode
    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const isCustomResolution: boolean =
      windowSize.width <= Breakpoints.b1600 && windowSize.height <= 700;

    // Tracking Function
    const onHandleSlideAction = (
      type: 'click' | 'swipe',
      direction: string,
    ) => {
      onSlideActionTracking(
        type,
        direction,
        offerInformation,
        trackingManager,
        undefined,
        dealer,
        isTier1,
      );
      onHandleCarouselLoaded(
        offerInformation,
        trackingManager,
        isTier1,
        undefined,
        dealer,
      );
    };

    const sliderKey = offerInformation.offers.length;

    const offersSlides = offerInformation.offers.map((offer: OffersModel) => (
      <NewOfferCard
        key={offer.dealId}
        modelInventoryHref={navigateToInventoryHref}
        offer={{
          ...offer,
          modelKey: offerInformation?.modelKey,
          modelDisplayName: offerInformation?.modelDisplayName,
          mediaImageUrl:
            transformIrisUrl(
              imageMap?.modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              offerInformation?.modelKey,
              imageMap?.isNewImage,
            ) ?? faHostServerUrl + AppConstants.Ngw6Logo960x432,
          modelCustomCta: offerInformation.modelCustomCta,
        }}
        isTier1
        modelTag={offerInformation.modelTag}
      />
    ));

    return (
      <NewStyleCarouselContainer key={sliderKey} isModalMode={mode === 'modal'}>
        <CustomSlider
          onActionTrackingTrigger={onHandleSlideAction}
          slides={offersSlides}
          slidesToShow={mode === 'modal' ? 2.2 : undefined}
          slidesToShowMedium={mode === 'modal' && isCustomResolution ? 1 : 2.1}
        />
      </NewStyleCarouselContainer>
    );
  },
);
