import {
  Breakpoints,
  Link,
  Richtext,
  TextCustom,
  useIsMobile,
} from '@vw-marketing/us-components';
import React from 'react';
import { ModalTrimModuleDetailsTranslations } from '../../typing/translations';
import { stringToPhoneFormat } from '../../utils/general';
import DealerInformationDetail from '../dealer-information-detail';
import CustomerCenter from '../icons/customer-center';
import PinPlace from '../icons/pin-place';
import {
  dealerNameStyle,
  descriptionTagOverrides,
  distanceStyle,
  informationStyle,
} from './helpers';
import {
  StyledModalDealerCard,
  StyledDealerDescriptionWrapper,
  StyledDealerNameWrapper,
  StyledDealerDetailsWrapper,
} from './styles';
import { DealerModel } from '../../hooks-store/typings/incentive-store';

interface ModalDealerCardProps {
  dealer?: DealerModel;
  translations: ModalTrimModuleDetailsTranslations;
}
const ModalDealerCard: React.FC<ModalDealerCardProps> = props => {
  const { dealer, translations } = props;
  const isMobile = useIsMobile(Breakpoints.b560);
  // const trackingManager = useTrackingManager();

  const onHandleTelephoneClick = () => {
    // const linkParams = {
    //   url: window.location.href,
    //   name: undefined,
    // };
    // trackingManager &&
    //   onVWBasicVehicleTelephoneNumberClick(
    //     trackingManager,
    //     dealer,
    //     linkParams,
    //   );
  };
  return (
    <StyledModalDealerCard className="details-dealer-card">
      <StyledDealerDescriptionWrapper className="details-dealer-description">
        <Richtext
          markdown={translations.dealerDescription}
          overrides={descriptionTagOverrides}
          dangerouslyParseRawHTML
        />
      </StyledDealerDescriptionWrapper>
      <StyledDealerNameWrapper className="details-dealer-name">
        <TextCustom {...dealerNameStyle}>{dealer?.name}</TextCustom>
        <TextCustom {...distanceStyle}>
          {` ${dealer?.distance.toFixed(2)} ${translations.distance}`}
        </TextCustom>
      </StyledDealerNameWrapper>
      <StyledDealerDetailsWrapper className="-dealer-details">
        <DealerInformationDetail
          Icon={PinPlace}
          label1={
            <TextCustom {...informationStyle}> {dealer?.address1} </TextCustom>
          }
          label2={
            <TextCustom {...informationStyle}>
              {dealer?.city + ', ' + dealer?.state + ' ' + dealer?.postalcode}
            </TextCustom>
          }
        />
        <DealerInformationDetail
          Icon={CustomerCenter}
          label1={
            isMobile ? (
              <Link
                href={dealer ? `tel: ${dealer?.phone}` : ''}
                onClick={onHandleTelephoneClick}
              >
                <TextCustom {...informationStyle}>
                  {' '}
                  {stringToPhoneFormat(dealer?.phone || '')}
                </TextCustom>
              </Link>
            ) : (
              <TextCustom {...informationStyle}>
                {stringToPhoneFormat(dealer?.phone || '')}
              </TextCustom>
            )
          }
        />
      </StyledDealerDetailsWrapper>
    </StyledModalDealerCard>
  );
};

export default ModalDealerCard;
