import React from 'react';

import _ from 'lodash';
import { Offers, TextCustom } from '@vw-marketing/us-components';

import { StyledTrimModuleOfferTypeContainer } from './styles';
import { textStyling } from './helpers';
import { ModelTag } from '../../hooks-store/typings/incentive-store';

interface TrimModuleOfferTypeProps {
  readonly modelTag?: ModelTag;
}

const TrimModuleOfferType: React.FC<TrimModuleOfferTypeProps> = ({
  modelTag,
}) => {
  return (
    <StyledTrimModuleOfferTypeContainer
      className="trim-module_offer-type-container"
      modelTag={modelTag}
    >
      <TextCustom {...textStyling}>
        <Offers variant="small" /> {modelTag?.label}
      </TextCustom>
    </StyledTrimModuleOfferTypeContainer>
  );
};

export default TrimModuleOfferType;
