// This is a pattern to organize all possible actions regarding this store
// Notice that every enum declared here is a reducer and need to be declared like an action

// Declaration of all the constants used through the app to manage incentive store data
enum IncentiveConstants {
  // Main data
  SetSharedConfigs = 'SET_SHARED_CONFIGS',
  CloneStoreData = 'CLONE_STORE_DATA',
  SetInitialData = 'SET_INITIAL_DATA',
  UpdateStoreByZip = 'UPDATE_STORE_BY_ZIP',
  // Filters functionality
  UpdateAppliedFilter = 'UPDATE_APPLIED_FILTER',
  ClearFilters = 'CLEAR_FILTERS',
  // Modal form functionality
  ActivateMobileFilterModal = 'ACTIVATE_MOBILE_FILTER_MODAL',
  CloseContactDealerForm = 'CLOSE_CONTACT_DEALER_FORM',
  SetModalFormData = 'SET_MODAL_FORM_DATA',
  SetDRModalData = 'SET_DR_MODAL_DATA',
  ResetStore = 'RESET_STORE',
  // Modal zip functionality
  UpdateModalZipState = 'UPDATE_MODAL_ZIP_STATE',
  // General functions
  SetPendingProcessState = 'SET_PENDING_PROCESS_STATE',
  SetModalInitialData = 'SET_MODAL_INITIAL_DATA',
}

export default IncentiveConstants;
