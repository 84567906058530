import {
  Breakpoints,
  createTagOverride,
  TextAppearance,
  TextCustom,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

export const descriptionTagOverrides: any = {
  strong: createTagOverride(TextCustom, {
    appearance: TextAppearance.label100,
    tag: TextTag.strong,
    style: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  }),
  p: createTagOverride(TextCustom, {
    appearance: TextAppearance.label100,
    tag: TextTag.p,
    style: {
      fontWeight: TextWeight.regular,
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  }),
  span: createTagOverride(TextCustom, {
    appearance: TextAppearance.label100,
    tag: TextTag.p,
    style: {
      fontWeight: TextWeight.regular,
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  }),
};

export const informationStyle: TextCustomProps = {
  appearance: TextAppearance.label100,
  tag: TextTag.span,
  style: {
    fontSize: TextSize.TextSize12,
    lineHeight: TextLineHeight.TextLineHeight20,
  },
};


export const dealerNameStyle: TextCustomProps = {
  appearance: TextAppearance.label200,
  tag: TextTag.h3,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight18,
    },
    [Breakpoints.b1280]: {
      fontSize: TextSize.TextSize18,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  },
};

export const distanceStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  tag: TextTag.span,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.light,
      fontSize: TextSize.TextSize12,
      lineHeight: TextLineHeight.TextLineHeight18,
    },
    [Breakpoints.b1280]: {
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  },
};
