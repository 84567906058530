
import { fetchGraphQL } from "./fetch-graphql";
import { createQueryString } from "./helpers/create-query";
import { handleServerResponse } from "./helpers/handle-server-response";
import { validateGraphQLServer } from "./helpers/validations";
import { OFFERS_DEALERS_QUERY } from "./queries/dealers";
import { FetchDealersByZipCode, ServiceConfig } from "./typing";


export const fetchDealersByZipCode = async ({zipCode, graphQLServer}: FetchDealersByZipCode) => {
    const requestBody = createQueryString(OFFERS_DEALERS_QUERY, {zipCode});
    if (!zipCode) return []
    try {
      validateGraphQLServer(graphQLServer);
        const response = await fetchGraphQL(graphQLServer as ServiceConfig, requestBody);
        if (response.data && response.data.dealers){
          return handleServerResponse(response.data, false);
        } 
        return handleServerResponse(response, true);
      } catch (error) {
        return handleServerResponse(error, true);
      }
}