import isBrowser from 'is-browser';
import React from 'react';
import { Inventory } from '../typing/inventory';
import {useFeatureServices} from './use-feature-services';
import { FeatureAppModelConfig } from '../typing/main';

type UniversalStateAction =
  | {readonly type: 'fetchingModelOverview'}
  | {readonly type: 'fetchedModelOverview'; fetchedModelOverview: FeatureAppModelConfig}
  | {readonly type: 'failedToFetchModelOverview'; error: Error};

type ModelOverviewResult =
  | {status: 'loading'}
  | {status: 'loaded'; modelOverview: FeatureAppModelConfig}
  | {status: 'failed'; error: Error; ssr: boolean};

export interface UniversalState {
  readonly modelOverviewResult?: ModelOverviewResult;
}

function universalStateReducer(
  state: UniversalState,
  action: UniversalStateAction,
): UniversalState {
  switch (action.type) {
    case 'fetchingModelOverview':
      return {...state, modelOverviewResult: {status: 'loading'}};

    case 'fetchedModelOverview':
      return {
        ...state,
        modelOverviewResult: {
          status: 'loaded',
          modelOverview: action.fetchedModelOverview,
        },
      };

    case 'failedToFetchModelOverview':
      return {
        ...state,
        modelOverviewResult: {
          status: 'failed',
          error: action.error,
          ssr: !isBrowser,
        },
      };

    default:
      return state;
  }
}

export function useUniversalState(): [
  UniversalState,
  React.Dispatch<UniversalStateAction>,
] {
  const {'feature-app-memory-store': memoryStore} = useFeatureServices();

  const [universalState, setUniversalState] = React.useState(
    memoryStore.getState() || {},
  );

  const dispatch: React.Dispatch<UniversalStateAction> = (action) => {
    const newUniversalState = universalStateReducer(universalState, action);

    // Change the state of the memory store to transfer it from one server-side
    // render pass to another.
    //memoryStore.setState(newUniversalState);

    // Set the react state to trigger a re-render on the client.
    setUniversalState(newUniversalState);
  };

  return [universalState, dispatch];
}
