import { styled } from '@vw-marketing/us-components';

export const StyledTrimModuleOfferTypeContainer = styled.div<any>`
${({ modelTag }) => `
    background: ${modelTag.background};
    border-radius: 5px;
    color: ${modelTag?.color};
    display: inline-block;
    padding: 3px 10px;
    width: max-content;
`}`;
