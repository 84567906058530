import { Disclaimer } from '@vw-marketing/us-components';

import { DisclaimerLanding, FeatureAppDisclaimers } from '../typing/main';
import { useDisclaimerConfiguration } from '../hooks/use-disclaimer-configuration';
import {
  InventoryDisclaimer,
  InventoryDisclaimers,
} from '../typing/offer-disclaimer';

export enum DisclaimerKeys {
  LandingKey = 'landing',
  ResultsKey = 'results',
  DetailsKey = 'details',
}

/**
 * Get a specific disclaimer key
 * @param pageName
 * @param modelKey
 */
export const getDisclaimerKeyByModelAndPage = (
  pageName: string,
  modelKey?: string,
): string | undefined => {
  if (!modelKey || !pageName) return;
  const { disclaimers } = useDisclaimerConfiguration();
  const filteredDisclaimers = getDisclaimersByPage(pageName, disclaimers);
  const key = filteredDisclaimers?.find((disclaimer: InventoryDisclaimer) =>
    disclaimer.models.includes(modelKey),
  );
  return key?.number;
};

/**
 * Get all the disclaimers of a page (landing, results, details)
 * @param pageName
 * @param disclaimers
 */
export const getDisclaimersByPage = (
  pageName: string,
  disclaimers?,
): InventoryDisclaimer[] | undefined => {
  if (!disclaimers) return;

  return disclaimers?.filter(disclaimer => disclaimer.pageName === pageName);
};

/**
 *  Process Inventory disclaimers to have the correct numbers and type
 * @param disclaimers Inventory disclaimers
 * @param useDisclaimerModule disclaimer Service
 * @returns Formatted Inventory disclaimers with correct numbers and type
 */
export const getDisclaimerKeys = (
  disclaimers: InventoryDisclaimers,
  useDisclaimerModule: (
    disclaimerText: string | undefined,
    scope?: string | undefined,
  ) => string | undefined,
) => {
  const newInventoryDisclaimers: Disclaimer[] = [];

  [
    DisclaimerKeys.LandingKey,
    DisclaimerKeys.ResultsKey,
    DisclaimerKeys.DetailsKey,
  ].forEach(pageKey => {
    disclaimers[pageKey]?.map((disclaimer: InventoryDisclaimer) => {
      const disclaimerKey = useDisclaimerModule(
        disclaimer.description,
        pageKey,
      );
      const newDisclaimer = {
        ...disclaimer,
        token: disclaimer.key,
        number: disclaimerKey as string,
        pageName: pageKey,
      };

      newInventoryDisclaimers.push(newDisclaimer);
    });
  });

  return newInventoryDisclaimers;
};

export const getLandingDisclaimers = (
  disclaimers?: FeatureAppDisclaimers,
  service?: (
    disclaimerText: string | undefined,
    scope?: string | undefined,
  ) => string | undefined,
): DisclaimerLanding[] | undefined => {
  if (!disclaimers || !disclaimers.landing) return undefined;
  disclaimers.landing.map((disclaimer: DisclaimerLanding) => {
    disclaimer.number = service && service(disclaimer.description);
  });
  return disclaimers.landing;
};
