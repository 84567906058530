import { ServerRequestV1 } from '@feature-hub/server-request';
import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';
import { getOffersSharedConfigurations } from '../hooks/get-offers-shared-configurations';
import { SharedConfigurationsResult } from '../typing/shared-configs';

const fetchSharedConfigs = async (
  serviceConfigProvider: ServiceConfigProviderV1,
  serverRequest?: ServerRequestV1,
): Promise<SharedConfigurationsResult> => {
  const { urlOrigin, urlPath } = getOffersSharedConfigurations(
    serviceConfigProvider,
    serverRequest,
  );
  const url = `${urlOrigin}${urlPath}`;
  let result;

  try {
    const response = await fetch(url);
    result = await response.json();
  } catch (error) {
    console.error(error);
  }

  return result;
};

export default fetchSharedConfigs;
