import { Breakpoints, styled } from "@vw-marketing/us-components";


export const StyledSliderWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 60px;
  }
`;


/* 
For slick-slider component
  flex-flow: row wrap;
  .slick-pagination-bar {
    order: 4;
    margin-top: 15px;
  }

  .slick-carousel {
    order: 3;
    width: 100%;
  }

  .slick-slide {
    & > :first-child {
      width: 100%;
    }
  }
*/