import { styled } from "@vw-marketing/us-components";

export const StyledTrimModuleOffersContainer = styled.div`
 display: flex;
 flex-direction: column;
 row-gap: 30px;

 .trim-module_view-more-offers {
    
 }
`