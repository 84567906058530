import React from 'react';

import { useFeatureServices } from './use-feature-services';
import _ from 'lodash';
import { StoreModel } from '../hooks-store/typings/store';
import { useStore } from '../hooks-store/store';
import { AppConstants } from '../utils/app-constants';
export interface FeatureAppTrackingManager {
  trackFeatureAppGlobalEvent(
    action: string,
    trackEvent: FeatureAppTrackingEvent,
  ): void;

  trackInventoryGlobalEvent(
    action: string,
    state?: StoreModel,
    trackEvent?: FeatureAppTrackingEvent,
  ): void;

  trackInternalNavigation(url: string, targetText: string): void;

  trackInventorySearchZip(zip: string);
}

type Brand = 'passenger' | 'commercial';

export interface FeatureAppTrackingEvent {
  eventInfo?: {
    contentId?: string;
    pageCategory?: string;
    sectionName?: string;
    templateType?: string;
    brand?: Brand;
    contentLabels?: [];
    pageName?: string;
  };

  environment?: {
    featureAppIdAppVersion?: string;
    region?: string;
    commerce?: boolean;
  };

  user?: {
    loginStatus?: boolean;
    locationZIP?: string;
    locationLatitude?: string;
    locationLongitude?: string;
    loginId?: string;
    customerType?: string;
    customerContractsCount?: string;
    dealerStatus?: boolean;
  };
  content?: {
    Asset?: [];
    Type?: string;
    Name?: string;
    ItemCount?: number;
    ItemPosition?: number;
  };
  personalization?: {
    status?: boolean;
    moduleId?: string;
    personalizable?: boolean;
    placeholderId?: string;
    contentId?: string;
    activityId?: string;
    activityName?: string;
    experienceId?: string;
    experienceName?: string;
    offerId?: string;
    offerName?: string;
  };

  configuration?: {
    Manufacturer?: string;
    BodyTypeName?: string;
    CarlineId?: string | string[];
    CarlineName?: string | string[];
    CarlineIsRecommendation?: string;
    ModelName?: string | string[];
    SalesgroupId?: string | string[];
    SalesgroupName?: string | string[];
    SalesgroupIsRecommendation?: string;
    EquipmentlineName?: string;
    EquipmentlineIsRecommendation?: string;
    ModelId?: string | string[];
    ModelTrimFeatures?: string;
    ModelYear?: string;
    ModelModelVersion?: string;
    WheelsAdditionalExternalRollingNoise?: string;
    PriceType?: string;
    PriceTypeParameter?: [];
    PriceRateCurrency?: string;
    PriceRateType?: string;
    PriceRateBasic?: string;
    PriceRateAdditional?: string;
    PriceRateTotal?: string;
    PriceTotalCurrency?: string;
    PriceTotalBasic?: string;
    PriceTotalAdditional?: string;
    PriceTotalTotal?: string;
    PriceCampaignID?: string;
    PriceCampaignDescription?: string;
    PriceCampaignValue?: number[];
    vehicleidentificationnumber?: string;
    ModelGearType?: string;
    ExteriorColorCode?: string;
    ExteriorColorName?: string;
    ExteriorColorIsRecommendation?: boolean;
    InteriorColorCode?: string;
    InteriorColorName?: string;
    InteriorColorIsRecommendation?: boolean;
    UsedVehicle?: boolean;
    DataSourceIsVicci?: boolean;
    UsedVehicleRegistrationDate?: string;
    UsedVehicleMileage?: Number;
    UsedVehicleComissionNumber?: string;
  };

  partner?: {
    informationBnr?: string;
    informationName?: string;
    informationDepartment?: string;
    informationZIP?: string;
    informationDistance?: number;
    SearchNumberOfResults?: number;
    onlinePaymentOption?: boolean;
    appointmentSystemName?: string;
    appointmentSystemUtilization?: boolean;
  };

  form?: {
    url?: string;
    name?: string;
    FormType?: string;
    FormTypeMultiple?: [];
    FormName?: string;
    NumberOfAppointments?: number;
    Appointments?: [];
    FormFieldName?: string;
    TextFieldValue?: [];
    PrefContactChannels?: string;
    FormFields?: [];
    ErrorFormFields?: object;
    ErrorAdressValidationFields?: [];
    FormVersion?: string;
    MarketingConsent?: [];
    OpportunityUID?: string;
    orderId?: string;
    LeadID?: string;
    NewsletterType?: [];
    FormStart?: boolean;
    CycleID?: string;
    Questionnaire?: [];
  };

  process?: {
    Name?: string;
    StepName?: string;
    StepNumber?: string;
    Components?: string[];
  };

  link?: {
    url?: string;
    name?: string;
    newTab?: boolean;
  };

  error?: {
    code?: string;
    message?: string;
    referringUrl?: string;
  };

  video?: {
    playerName?: string;
    title?: string;
    id?: string;
    platform?: string;
    url?: string;
    milestone?: number;
    timeplayed?: number;
    length?: number;
  };

  filter?: {
    results?: [];
    numberOfResults?: number;
    filterlist?: any[];
    sortOrder?: any[];
    type?: string;
    name?: string;
    values?: any[];
    lowerbound?: string;
    upperbound?: string;
    displayType?: string;
  };
  product?: {
    List?: any;
  };
  ownership?: {
    vehicle?: [];
  };

  tab?: {
    name?: string;
  };

  calculation?: {
    FinancingPaymentMethod: string;
    FinancingCurrency: string;
    FinancingCashPrice: number;
    FinancingTotalSum: number;
    FinancingMonthlyPayment: number;
    FinancingFinalPayment: number;
    FinancingInterestEffective: number;
    FinancingInterestDebt: number;
    FinancingDuration: number;
  };
}

export interface TrackingEvent {
  readonly [key: string]: object;
}

const defaultTrackingEvent: FeatureAppTrackingEvent = {
  eventInfo: {
    pageName: 'FA Template',
    brand: 'passenger',
    templateType: 'Feature App Template',
    pageCategory: 'FA Template',
  },

  environment: {
    featureAppIdAppVersion: '2.0',
    commerce: false,
  },
  user: undefined,
  content: undefined,
  personalization: undefined,
  configuration: undefined,
  partner: undefined,
  form: undefined,
  process: undefined,
  link: undefined,
  error: undefined,
  video: undefined,
  filter: undefined,
  product: undefined,
  ownership: undefined,
  tab: undefined,
  calculation: undefined,
};

export function getDefaultTrackingEvent() {
  return _.cloneDeep(defaultTrackingEvent);
}

function buildTrackingEvent(
  cusTrackEvent?: FeatureAppTrackingEvent,
  model?: StoreModel,
): FeatureAppTrackingEvent {
  if (cusTrackEvent?.partner) {
    cusTrackEvent.partner.SearchNumberOfResults = undefined;
  }
  if (cusTrackEvent?.eventInfo && !cusTrackEvent.eventInfo.sectionName) {
    cusTrackEvent.eventInfo.sectionName =
      model?.pageName === AppConstants.Tier1SimplifiedVersion
        ? 'Offers'
        : 'Inventory Available Offers';
  }
  if (cusTrackEvent?.partner) {
    cusTrackEvent.partner.SearchNumberOfResults = undefined;
  }
  return cusTrackEvent ?? _.cloneDeep(defaultTrackingEvent);
}

export function useTrackingManager(): FeatureAppTrackingManager {
  const trackingManager = useFeatureServices()['tracking']!;
  const [store] = useStore();

  return React.useMemo((): FeatureAppTrackingManager => {
    function track(action: string, event: FeatureAppTrackingEvent): void {
      if (trackingManager) {
        trackingManager.track(action, (event as unknown) as TrackingEvent);
      }
    }

    function trackNavigation(
      action: string,
      url: string,
      targetText: string,
    ): void {
      track(action, {
        ...defaultTrackingEvent,
        link: { url, name: targetText },
      });
    }

    return {
      trackFeatureAppGlobalEvent(
        event: string,
        trackEvent: FeatureAppTrackingEvent,
      ): void {
        track(event, buildTrackingEvent(trackEvent));
      },
      trackInventoryGlobalEvent(
        event: string,
        state?: StoreModel,
        trackEvent?: FeatureAppTrackingEvent,
      ): void {
        let curState = store;
        if (state) {
          curState = state;
        }
        track(event, buildTrackingEvent(trackEvent, curState));
      },

      trackInternalNavigation(url: string, targetText: string): void {
        trackNavigation(
          'VWBasic_InventoryInternalNavigation_Click',
          url,
          targetText,
        );
      },
      trackInventorySearchZip(zip: string): void {
        track('VWBasic_FilterSearchButton_Click', {
          ...defaultTrackingEvent,
          partner: {
            informationZIP: zip,
          },
        });
      },
    };
  }, [trackingManager]);
}
