import {
  TextCustomProps,
  TextAppearance,
  TextTag,
  Breakpoints,
  TextWeight,
  TextSize,
  TextLineHeight,
  TextColor,
} from '@vw-marketing/us-components';

export const modalTitleTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  tag: TextTag.h1,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize32,
      lineHeight: TextLineHeight.TextLineHeight36,
      color: TextColor.inherit
    },
    [Breakpoints.b1280]: {
      lineHeight: TextLineHeight.TextLineHeight36,
    },
  },
};
