import React from 'react';
import { FeatureAppDefinition } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import { ThemeProvider, styled } from '@vw-marketing/us-components';
import { featureAppMemoryStoreDefinition } from './store/feature-app-memory-store';
import { FeatureAppEnvironmentContext } from './hooks/use-feature-app-environment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FeatureAppConfig, FeatureAppDependencies } from './typing/main';
import { defineZipManager } from '@volkswagen-onehub/zip-manager';
import { InventoryContext } from './hooks/use-inventory';
import { defaultTranslation } from './config/translations';
import { getPriceData } from './utils/configuration';
import { App } from './app';
import { cleanStore } from './hooks-store/store';
import configureStore from './hooks-store/incentive-store';
import { TranslationsContext } from './hooks/use-translations';
import { translations } from './translations';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { HistoryContext } from './hooks/use-history';

declare var __VERSION__: String;

export interface SpecialEventFilterOption {
  isActive?: boolean;
  value?: string;
  text?: string;
}

export interface SalesEventOffersIds {
  [key: string]: number[]; // Any string key with a value of an array of numbers
}

export interface SpecialEventConfig {
  filterOptions?: SpecialEventFilterOption[];
}

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:history': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'car-configuration': '^2.0.0',
      'disclaimer-manager': '^1.1.0',
      'feature-app-memory-store': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'layer-manager': '^2.3.0',
      'locale-service': '^1.0.0',
      'navigation-service': '^1.1.0',
      'service-config-provider': '^1.0.0',
      'showroom-navigation': '^1.1.0',
      'single-configuration-flow-navigator': '^1.0.0',
      'zip-manager': '^1.2.1',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'breadcrumb-service': '^1.0.0',
      'compare': '^2.0.0',
      'data-version-service': '^1.0.0',
      'fa-content-service': '^1.0.0',
      'filter-service': '^2.0.0',
      'page-info-service': '^1.0.0',
      'tracking': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [
    featureAppMemoryStoreDefinition,
    defineZipManager({ countryCode: 'US' }),
  ],

  create: env => {
    const { featureServices, baseUrl, done } = env;

    const {
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'feature-app-memory-store': memoryStore,
      'locale-service': localeService,
      's2:history': historyService,
    } = featureServices;

    const serializedState = serializedStateManager.getSerializedState();
    if (serializedState) {
      const serializedStateJson = JSON.parse(serializedState);

      if (!memoryStore.getState()) {
        memoryStore.setState(serializedStateJson);
      }
    }

    serializedStateManager.register(() =>
      JSON.stringify(memoryStore.getState()),
    );

    const history = asyncSsrManager
      ? historyService.createStaticHistory()
      : historyService.createBrowserHistory();

    const priceData = getPriceData(localeService);

    cleanStore(memoryStore);
    configureStore();

    const StyledVersionContainer: any = styled.div`
      display: none;
    `;
    return {
      undefined,
      render: () => (
        <>
          <HistoryContext.Provider value={history}>
            <FeatureAppEnvironmentContext.Provider value={env}>
              <InventoryContext.Provider
                value={{ translations: defaultTranslation, priceData }}
              >
                <TranslationsContext.Provider value={translations.en}>
                  <ThemeProvider theme="main">
                    <StyledVersionContainer>
                      Feature App Version :: SOFA :: {__VERSION__}
                    </StyledVersionContainer>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <App baseUrl={baseUrl} done={done} />
                    </LocalizationProvider>
                  </ThemeProvider>
                </TranslationsContext.Provider>
              </InventoryContext.Provider>
            </FeatureAppEnvironmentContext.Provider>
          </HistoryContext.Provider>
        </>
      ),
    };
  },
};

export default featureAppDefinition;
