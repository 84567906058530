import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledModalTrimModuleDetailsContainer: any = styled.div`
  ${() => `
  display: grid;
  grid-template-columns: auto auto;
  
  grid-template-rows: auto;
  margin: 30px;
  grid-template-areas:
  'title'
  'image' 
  'offer'
  'dealer';
  @media (min-width: ${Breakpoints.b960}px) {
    margin: 70px 60px;
    grid-template-areas:
    'title title'
    'image offer'
    'dealer offer'
    '. offer';
  }
  

  div[class*='image'] {
    grid-area: image;
    padding-top: 15px;

  }
  div[class*='dealer-details'] {
    grid-area: dealer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;   

  }
  div[class*='title'] {
    width: 100%;
    margin-bottom: 10px;
    grid-area: title;
  }
  div[class*='offer-details'] {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-area: offer;
  }
  `}
`;
