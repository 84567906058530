import React from 'react';

import {
  DisableFocusWrapper,
  LinkButton,
  TextCustom,
} from '@vw-marketing/us-components';

import { Offer } from '../../typing/offer';
import {
  getPrettyOfferLocale,
  offerCardLegalStyle,
  offerCardLocaleStyle,
  offerCardTitleStyle,
  offerViewDetailsStyle,
} from './helpers';
import { ModelTag } from '../../hooks-store/typings/incentive-store';
import { StyledTrimModuleOfferCard } from './styles';
import { TrimModuleOfferCardTranslations } from '../../typing/translations';
import { useStore } from '../../hooks-store/store';
import TrimModuleOfferType from '../trim-module-offer-type';

interface TrimModuleOfferCardProps {
  modelKey: string;
  modelTag?: ModelTag;
  offer: Offer;
  onViewDetails: (offer: Offer) => void;
  translation: TrimModuleOfferCardTranslations;
}

const TrimModuleOfferCard: React.FC<TrimModuleOfferCardProps> = ({
  modelKey,
  modelTag,
  offer,
  onViewDetails,
  translation,
}) => {
  const [store] = useStore();
  const { salesEventOffersIds } = store.fetchedSharedConfigs;
  const isSalesEventOffer: boolean = salesEventOffersIds[modelKey]?.includes(offer.dealId) ?? false;
  const offerLocaleText: string = getPrettyOfferLocale(
    offer.localeType,
    offer.dealEndDate,
    translation.offerLocale,
  );

  return (
    <StyledTrimModuleOfferCard>
      {isSalesEventOffer && <TrimModuleOfferType modelTag={modelTag} />}
      <div className="offer-title">
        <TextCustom {...offerCardTitleStyle}>{offer.offerTitle}</TextCustom>
      </div>
      <div className="offer-subtitle">
        <TextCustom {...offerCardLegalStyle}>{offer.offerSubtitle}</TextCustom>
      </div>
      <div className="offer-locale-type">
        <TextCustom {...offerCardLocaleStyle}>{offerLocaleText}</TextCustom>
      </div>
      <div className="offer-view-details">
        <DisableFocusWrapper>
          {/* @ts-ignore */}
          <LinkButton isInline onClick={onViewDetails.bind(null, offer)}>
            <TextCustom {...offerViewDetailsStyle}>
              {translation.viewDetailsCta}
            </TextCustom>
          </LinkButton>
        </DisableFocusWrapper>
      </div>
    </StyledTrimModuleOfferCard>
  );
};

export default TrimModuleOfferCard;
