import { gql } from '@apollo/client';

export const MODELS_CONFIG = gql`
query getModelByCarlineId($id: String){
  modelByCarlineId @client(id: $id){
    name
    offerModelKey
  }
}
`;
