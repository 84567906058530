import { History } from 'history';
import moment from 'moment';
import { CustomCTAData, ModelImage, ModelOrder } from '../config/model-order';
import { FeatureAppModelConfig, RedirectToPage } from '../typing/main';
import _ from 'lodash';
import {
  allImage,
  CarlineConfigModel,
  defaultImage,
  ImageCategoryMapModel,
  ModelConfig,
  OfferImagesMapModel,
} from '../typing/model-config';
import isBrowser from 'is-browser';
import { AppConstants } from './app-constants';

/**
 * Navigate to tier 1
 * @param navigationService Navigation service
 */
export const navigateTier1Ref = ({
  navigationService,
  urlParams,
  pageName = AppConstants.Tier1RedirectPageName,
  FAName = AppConstants.Tier1RedirectFAName,
}: RedirectToPage): string | undefined | void => {
  let navigateToTier1;
  if (urlParams) {
    navigateToTier1 = navigationService.navigateTo(pageName, {
      [FAName]: {
        pathname: '/',
        search: `?${urlParams}`,
      },
    });
  } else {
    navigateToTier1 = navigationService.navigateTo(pageName, {
      [FAName]: '/',
    });
  }
  return navigateToTier1?.createHref();
};

export const getModelNameByCarlineId = (
  carlineId: string,
  modelsConfig?: ModelConfig,
) => {
  const modelName = _.values(modelsConfig).find(
    (model: CarlineConfigModel) => model.carlineId === carlineId,
  );
  return modelName?.name ?? '';
};

/**
 * inject google API script to the HTML body tag
 */
export const appendGoogleApiScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = AppConstants.GoogleApiUrl;
  document.body.appendChild(script);
};

/**
 * inject style to the head to do a workaround
 */
export const appendSectionFAStyle = () => {
  const style = document.createElement('style');
  style.textContent = `.offers-fa#featureappsection {
      margin-top: -16px;
    }
    @media (min-width: 560px) {
      .offers-fa#featureappsection {
        margin-top: -40px;
      }
    }
    @media (min-width: 960px) {
      .offers-fa#featureappsection {
        margin-top: -32px;
      }
    }`;
  document.head.appendChild(style);
};

/**
 * Unsets z-index value for pagemain container
 */
export const unsetPagemainZindex = () => {
  // The class name is used due to lack of id
  let pagemainContainer = document.getElementsByClassName('pagemain')[0];
  if (pagemainContainer && pagemainContainer.parentElement)
    pagemainContainer.parentElement.style.zIndex = 'unset';
};

/**
 * History listener for Tier1
 * Manage scroll when url is changed
 * @param history
 */
export const historyListener = (history: History) => {
  history.listen(() => {
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
      if (history.action === 'POP') {
        return;
      }
      if (history.action === 'PUSH') {
        return;
      }
      // In all other cases, check fragment/scroll to top
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        } else {
          window.scrollTo(0, 0);
        }
      } else {
        window.scrollTo(0, 0);
      }
    });
  });
};

/**
 * Get Offers image mapping from Models config data
 */
export const getOfferImageMapFromModelsConfig = (
  modelsConfig?: FeatureAppModelConfig,
): OfferImagesMapModel | undefined => {
  if (!modelsConfig) return undefined;

  let offerImagesMap: OfferImagesMapModel = {};
  _.each(modelsConfig, ({ modelYears, offerModelKey }) => {
    if (modelYears && offerModelKey)
      offerImagesMap[offerModelKey] = modelYears as ImageCategoryMapModel;
  });

  return _.isEmpty(offerImagesMap)
    ? undefined
    : {
        ...offerImagesMap,
        default: defaultImage,
        all: allImage,
      };
};

/**
 * Navigate to tier 1
 * @param navigationService Navigation service
 */
export const navigateTier1 = ({
  navigationService,
  urlParams,
  pageName = AppConstants.Tier1RedirectPageName,
  FAName = AppConstants.Tier1RedirectFAName,
}: RedirectToPage): string | undefined | void => {
  if (urlParams) {
    const navigateToTier1 = navigationService.navigateTo(pageName, {
      [FAName]: {
        pathname: '/',
        search: `?${urlParams}`,
      },
    });
    navigateToTier1?.push();
    return navigateToTier1?.createHref();
  } else {
    const navigateToTier1 = navigationService.navigateTo(pageName, {
      [FAName]: '/',
    });
    navigateToTier1?.push();
  }
};

/**
 * Validate if the color name is a valid css color
 * @param stringToTest Color name or hex color
 */
export const isValidTextColor = (stringToTest: string) => {
  if (!isBrowser) {
    return false;
  }

  // Alter the following conditions according to your need.
  if (stringToTest === '') {
    return false;
  }
  if (stringToTest === 'inherit') {
    return false;
  }
  if (stringToTest === 'transparent') {
    return false;
  }

  const image = document.createElement('img');
  image.style.color = 'rgb(0, 0, 0)';
  image.style.color = stringToTest;
  if (image.style.color !== 'rgb(0, 0, 0)') {
    return true;
  }
  image.style.color = 'rgb(255, 255, 255)';
  image.style.color = stringToTest;

  return image.style.color !== 'rgb(255, 255, 255)';
};

/**
 * Return white or black acording the hexcolor
 * @param hexcolor An hexadecimal color like #000, 000, 000000
 */
export const getHexContrast = (hexcolor: string) => {
  let excolorAux = hexcolor;

  // If a leading # is provided, remove it
  if (excolorAux.slice(0, 1) === '#') {
    excolorAux = excolorAux.slice(1);
  }

  // If a three-character hexcode, make six-character
  if (excolorAux.length === 3) {
    excolorAux = excolorAux
      .split('')
      .map(hex => hex + hex)
      .join('');
  }

  // Convert to RGB value
  const r = parseInt(excolorAux.substr(0, 2), 16);
  const g = parseInt(excolorAux.substr(2, 2), 16);
  const b = parseInt(excolorAux.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? 'black' : 'white';
};

/**
 * Validate if is a valid hex color
 * @param hexColor Hex color format, you can use it without #
 */
export const isValidHexColor = (hexColor: string) => {
  const hexColorRegExp = /(^#?[a-fA-F0-9]{6}$|^#?[a-fA-F0-9]{3}$)/;
  return hexColorRegExp.test(hexColor);
};

/**
 * Given a color name this function return the hexadecimal value
 * @param str Color name
 */
export const getHexColorByName = (str: string): string => {
  if (!isBrowser) {
    return '#000';
  }

  const ctx = document.createElement('canvas').getContext('2d');
  if (ctx) {
    ctx.fillStyle = str;

    return ctx.fillStyle;
  }

  return '#000';
};

/**
 * Return white or black acording the RGB color
 * @param rgb RGB color with this format [225, 0, 0]
 * the fourth parameter can be passed but will be ignored
 */
export const getRgbContrast = (rgb: any) => {
  // randomly update
  rgb[0] = Math.round(Math.random() * 255);
  rgb[1] = Math.round(Math.random() * 255);
  rgb[2] = Math.round(Math.random() * 255);

  const o = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);

  return o > 125 ? 'black' : 'white';
};

/**
 * Return the contrast white or black using the given color
 * @param ele RBG, Color name, or hexadecimal color
 */
export const getContrastColorText = (ele: any) => {
  if (typeof ele === 'string' || ele instanceof String) {
    if (isValidHexColor(ele as string)) {
      return getHexContrast(ele as string);
    }

    if (isValidTextColor(ele as string)) {
      return getHexContrast(getHexColorByName(ele as string) as string);
    }

    return 'black';
  }

  return getRgbContrast(ele);
};

/**
 * Recognizes if url has defined query params
 * Auxiliary Function
 * @param url
 * @returns boolean
 */
const hasQueryParams = (url: string) => {
  const queryParamsPattern = new RegExp(/\?.+=.*/g);
  return queryParamsPattern.test(url);
};

/**
 * Blocks the scroll action when some layer is active
 * @param toggle
 */
export const blockScrollAction = (toggle: boolean) => {
  if (isBrowser) {
    if (toggle) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }
};

export const categorizeModels = (models: ModelOrder[]) => {
  const categorizedModels: {
    [category: string]: { filterKey: string; text: string; value: string }[];
  } = {};
  models.forEach(model => {
    const { category, modelName } = model;
    const text = modelName;
    const value = modelName.toLowerCase();

    if (category) {
      if (!categorizedModels[category]) {
        categorizedModels[category] = [];
      }

      categorizedModels[category].push({ filterKey: 'model', text, value });
    }
  });

  return categorizedModels;
};

/**
 * Apply the scroll to top
 * @param scrollStepInPx Interval of px to scroll to top
 * @param behavior window.scrollTo parameter
 */
type Behavior = 'smooth' | 'auto' | undefined;
export const scrollToTop = (scrollStepInPx: number, behavior?: Behavior) => {
  if (behavior) {
    window.scrollTo({ top: 0, left: 0, behavior });
  } else {
    const intervalId = setInterval(() => {
      window.scroll(0, window.pageYOffset - scrollStepInPx);
      if (window.pageYOffset <= 0) {
        clearInterval(intervalId);
      }
    }, 50);
  }
};

/**
 * Convert the number to thousand format
 * @param value String with a number
 */
export const separateThousand = (value: string) => {
  const intValue = parseInt(value, 10);

  return intValue < 1000
    ? intValue
    : intValue.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
/**
 * Give properties to the images from iris.
 * The width and height need to be proportional to 960*432,
 * for instance, 480*216 and so on
 * @param mediaImageUrl Image from iris
 * @param pov Image position
 * @param width Image width
 * @param height Image height
 * @param modelYear Vehicle's year
 * @param modelName Vehicle's model
 */
export const transformIrisUrl = (
  mediaImageUrl?: string,
  pov?: string,
  width = '960',
  height = '432',
  modelYear = '',
  modelName = '',
  isNewImage = false,
): string | undefined => {
  if (!mediaImageUrl) {
    return mediaImageUrl;
  }

  const urlQueryParams = new URLSearchParams(mediaImageUrl);
  const isIrisServer = mediaImageUrl.toLowerCase()?.includes('iris');
  if (isNewImage || !urlQueryParams.has('pov')) {
    const newWidthParam =
      hasQueryParams(mediaImageUrl) || isIrisServer
        ? `&width=${width}`
        : `?width=${width}`;
    return mediaImageUrl + newWidthParam;
  }

  const regex = /pov=(.*?),/g;
  const baseUrl = decodeURIComponent(mediaImageUrl).replace(
    regex,
    `pov=${pov},`,
  );
  const useNewFormat =
    modelYear && modelName && +modelYear >= 2020 ? true : false;

  const povE13 = useNewFormat
    ? `&width=${Math.floor(+width * 1.2)}&height=${Math.floor(
        +height * 1.2,
      )}&cut=1&quality=90&&crop=${Math.floor(+width * 0.07)},${Math.floor(
        +height * 0.47,
      )},${width},${height}`
    : `&h=4100&quality=90&w=7900&width=${width}&height=${height}&x=900&y=3000`; //accurancy
  // : `&h=4200&quality=90&w=7500&width=${width}&x=1250&y=3000`; // cropped
  // : `&h=4000&quality=90&w=8000&width=${width}&x=700&y=3000`; // No centered
  // : `&h=4200&quality=90&w=7500&width=300&x=1020&y=300`; // smaller;

  const povE06 = useNewFormat
    ? `&width=${Math.floor(+width * 1.2)}&height=${Math.floor(
        +height * 1.2,
      )}&cut=1&quality=90&crop=${Math.floor(+width * 0.06)},${Math.floor(
        +height * 0.47,
      )},${width},${height}`
    : `&h=4200&quality=90&w=7000&width=${width}&height=${height}&x=1050&y=3000`;

  switch (pov) {
    case AppConstants.IrisImgPositionE13:
      return baseUrl + povE13;
    case AppConstants.IrisImgPositionE06:
      return baseUrl + povE06;
    default:
      return baseUrl + povE13;
  }
};

/*
 * Returns the text from a HTML string
 *
 * @param {html} String The html string
 */
export const clearText = (text: string) => {
  if (!text) return '';
  return text
    .replace(/&quot;/g, '"')
    .replace(/&iacute;/g, 'í')
    .replace(/&mdash;/g, '—')
    .replace(/&reg;/g, '®')
    .replace(/&apos;/g, "'")
    .replace(/&acute;/g, '´')
    .replace(/&trade;/g, '™')
    .replace(/&copy;/g, '©')
    .replace(/&amp;/g, '&')
    .replace(/&rsquo;/g, '’');
};

/**
 * Display date format
 * @param date date using this format 2020-12-31
 */
export const getDateFormat = (date: string) =>
  moment(date)
    .format('MMMM Do, YYYY')
    .replace(',', '');

/**
 * Type offer label style
 * @param offerType Offer type
 */
export const getOfferTypeLabelInfo = (offerType: string) => {
  switch (offerType.toLowerCase()) {
    case AppConstants.OfferTypeLease:
      return {
        label: 'Lease',
        backgroundColor: '#ffd100',
        textColor: '#000000',
      };
    case AppConstants.OfferTypeAPR:
      return {
        label: 'APR',
        backgroundColor: '#e4002c',
        textColor: '#ffffff',
      };
    case AppConstants.OfferTypeOther:
      return {
        label: 'Other',
        backgroundColor: '#067bc6',
        textColor: '#ffffff',
      };
    case AppConstants.OfferTypeSalesEventDeal:
      return {
        label: 'Sales Event Deal',
        backgroundColor: '#E4002C',
        textColor: '#ffffff',
      };
    default:
      return {
        label: 'Other',
        backgroundColor: '#067bc6',
        textColor: '#ffffff',
      };
  }
};

/**
 * Change the text to camel case format
 * @param str String text
 */
export const toCamelCase = (str: string) =>
  str
    .replace(/-/g, ' ')
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\s(.)/g, ($1: string) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1: string) => $1.toLowerCase());

export const manageClassById = (
  add: boolean,
  id: string,
  className: string,
) => {
  const el = document.getElementById(id);

  if (el) {
    add ? el.classList.add(className) : el.classList.remove(className);
  }
};
const getModelConfigBySlug = (
  modelSlug?: string,
  modelsConfig?: ModelConfig,
) => {
  const modelBySlug = _.values(modelsConfig).find(
    (model: CarlineConfigModel) => model.slug === modelSlug,
  );
  return modelBySlug;
};

export const getModelWithCustomCta = (
  modelkey: string,
  modelsOrder: ModelOrder[],
  modelsConfig?: FeatureAppModelConfig,
): CustomCTAData | undefined => {
  let customCta: CustomCTAData | undefined;
  const modelOrderDta = modelsOrder.find(
    (model: ModelOrder) => model.modelKey === modelkey,
  );

  // Local configuration priority
  if (
    typeof modelOrderDta?.isStandard !== 'undefined' &&
    !modelOrderDta?.isStandard
  ) {
    customCta = modelOrderDta.customCTAData;
  } else {
    const modelConfigDta =
      (modelsConfig &&
        modelOrderDta?.carlineId &&
        modelsConfig[modelOrderDta?.carlineId]) ||
      getModelConfigBySlug(
        modelOrderDta?.modelName.toLowerCase().replace(/ /g, '-'),
        modelsConfig,
      );
    if (typeof modelConfigDta?.modelCustomCta !== 'undefined') {
      customCta = modelConfigDta.modelCustomCta;
    }
  }
  return customCta;
};

export const getModelImageData = (
  modelkey: string,
  modelsOrder: ModelOrder[],
  modelsConfig?: FeatureAppModelConfig,
) => {
  let modelImage: ModelImage | undefined;
  const modelOrderDta = modelsOrder.find(
    (model: ModelOrder) => model.modelKey === modelkey,
  );

  // Local configuration priority
  if (modelOrderDta?.modelImage) {
    modelImage = modelOrderDta.modelImage;
  } else {
    const modelConfigDta =
      (modelsConfig &&
        modelOrderDta?.carlineId &&
        modelsConfig[modelOrderDta?.carlineId]) ||
      getModelConfigBySlug(
        modelOrderDta?.modelName.toLowerCase().replace(/ /g, '-'),
        modelsConfig,
      );
    if (modelConfigDta) {
      modelImage = {
        modelImage: modelConfigDta.carImage || '',
        modelImageAlt: modelConfigDta.carImageAlt,
        modelYear: modelConfigDta.modelYear,
        background: modelConfigDta.background || '',
        isNewImage: modelConfigDta.isNewImage,
      };
    }
  }
  return modelImage;
};

/**
 * Enabled or disabled the scroll functionality
 * @param disabled Scroll state
 */
export const disabledScroll = (disabled: boolean) => {
  if (isBrowser) {
    disabled
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
  }
};

/**
 * Decode all the symbols and characters
 * @param object Final result to be clean
 */
export const cleanUpCharacters = (object: any) => {
  try {
    let str = JSON.stringify(object);
    str = str
      .replace(/&quot;/gi, '\\"') // double quote
      .replace(/&rsquo;/gi, '’')
      .replace(/&mdash;/gi, '—')
      .replace(/&reg;/gi, '®') // registered ®
      .replace(/&apos;/gi, "'") // single quote
      .replace(/&acute;/gi, '´')
      .replace(/&trade;/gi, '™') // trademark
      .replace(/&copy;/gi, '©') // copyright
      .replace(/&ocirc;/gi, 'ô')
      .replace(/&amp;/gi, '&'); // ampersand

    return JSON.parse(str);
  } catch (error) {
    console.log(
      'WARNING! There is an error cleaning up json object, Error: ',
      error,
    );
    return object;
  }
};

/**
 * Display date format
 * @param date date using this format 2020-12-31
 */
export const getOfferDateFormat = (date: string) =>
  moment(date)
    .format('MMMM Do, YYYY')
    .replace(',', '');

/*
 * Returns the text from a HTML string
 *
 * @param {html} String The html string
 */
export const sanitizeHtmlText = (text: string) => {
  if (!text) return '';
  return text
    .replace(/&quot;/g, '"')
    .replace(/&iacute;/g, 'í')
    .replace(/&mdash;/g, '—')
    .replace(/&reg;/g, '®')
    .replace(/&apos;/g, "'")
    .replace(/&acute;/g, '´')
    .replace(/&trade;/g, '™')
    .replace(/&copy;/g, '©')
    .replace(/&amp;/g, '&')
    .replace(/&rsquo;/g, '’');
};

export const sanitizeLocaleType = (locale?: string) => {
  if (!locale) return '';
  switch (locale.toLowerCase()) {
    case 'daa':
      return 'Local';
    default:
      return locale;
  }
};

/**
 * Give format to the Phone number
 * @param phone Phone number
 */
export const stringToPhoneFormat = (phone: string) =>
  phone?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
