import {
  TextCustomProps,
  TextAppearance,
  TextSize,
  TextWeight,
  TextAlignment,
  TextLineHeight,
  TextTag,
  TextColor,
} from '@vw-marketing/us-components';

export const TYPE_STYLE_MAP = {
  'other': {
    text: "Other",
    background: '#067BC6',
    color: '#FFFFFF',
  },
  'lease': {
    text: "Lease",
    background: '#FFD100',
    color: '#000000',
  },
  'apr': {
    text: "APR",
    background: '#E4002C',
    color: '#FFFFFF',
  },
  '': {
    text: "Other",
    background: '#067BC6',
    color: '#FFFFFF',
  },
};

export const DEFAULT_OFFER_TYPE = 'other';

export const textStyling: TextCustomProps = {
  appearance: TextAppearance.copy100,
  style: {
    fontSize: TextSize.TextSize14,
    fontWeight: TextWeight.bold,
    textAlign: TextAlignment.center,
    lineHeight: TextLineHeight.TextLineHeight16,
    color: TextColor.inherit
  },
  tag: TextTag.span,
};
