import { fetchGraphQL } from './fetch-graphql';
import { createQueryString } from './helpers/create-query';
import { handleServerResponse } from './helpers/handle-server-response';
import { PRODUCT_OFFER_QUERY } from './queries/offers';
import { FetchOffersByZipCode, ServiceConfig } from './typing';
import { validateGraphQLServer } from './helpers/validations';
import { fetchOffersNational } from './fetch-offers-national';


export const fetchOffersByZipCode = async ({
  zipCode,
  graphQLServer,
}: FetchOffersByZipCode) => {
  if (!zipCode) return fetchOffersNational({graphQLServer})
  const requestBody = createQueryString(PRODUCT_OFFER_QUERY, { zipCode });
  try {
    validateGraphQLServer(graphQLServer);
    const response = await fetchGraphQL(graphQLServer as ServiceConfig, requestBody);
    if (response.data && response.data.offers) {
      return handleServerResponse(response.data.offers, false);
    }
    return handleServerResponse(response, true);
  } catch (error) {
    return handleServerResponse(error, true);
  }
};
