import {
  Breakpoints,
  BreakpointWrapper,
  Button,
  ButtonAppearance,
  ButtonType,
  ChevronDown,
  CloseButton,
  Divider,
  Filter,
  LinkButton,
  TextAppearance,
  TextColor,
  TextCustom,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import React, { useMemo } from 'react';
import {
  AttributeModel,
  FilterAttributeModel,
} from '../../../../../hooks-store/typings/incentive-store';
import { ElementBounding } from '../../../../../hooks/use-element-bounding';
import { useTranslations } from '../../../../../hooks/use-translations';
import FilterModelYear from '../../filter-model-year';
import FilterModels from '../../filter-models';
import { modalLabelTextStyle } from '../helpers';
import {
  StyledButtonsGroup,
  StyledFilterCloseButtonWrapper,
  StyledFilterDropdownButton,
  StyledFilterDropdownContainer,
  StyledFilterDropdownContent,
  StyledFilterLabelContainer,
  StyledFilterMobileHeader,
  StyledFiltersContainer,
  StyledHeaderTitleContainer,
  StyledIcon,
} from './styles';
export interface DefaultFilterElement {
  isChecked: boolean;
  isDisabled: boolean;
  key: string;
  value: string;
  total: number;
  type: string;
}

export interface DefaultFiltersModel {
  modelYear?: DefaultFilterElement[];
  models?: DefaultFilterElement[];
}

interface DropdownContainerProps {
  toggle: boolean;
  modalActive: boolean;
  filters?: FilterAttributeModel[];
  appliedFilters?: AttributeModel[];
  onHandleToggle: () => void;
  labelStyle: TextCustomProps;
  onViewOffers: () => void;
  onClearAllFilters: () => void;
  appliedFilterAmount: string;
  matchingOffers: number;
  mobileModalFilterActivated: boolean;
  modelYears?: FilterAttributeModel;
  categorizedModels: any;
  dropdownRef: React.MutableRefObject<HTMLDivElement | null>;
  isSticky: boolean;
  elementBounding?: ElementBounding;
  dropdownContentRef: (element: HTMLElement | null) => void;
  onHandleCloseButton: () => void;
}

const DropdownContainer: React.FC<DropdownContainerProps> = props => {
  const {
    toggle,
    modalActive,
    filters,
    appliedFilters,
    onHandleToggle,
    labelStyle,
    onViewOffers,
    onClearAllFilters,
    appliedFilterAmount,
    matchingOffers,
    mobileModalFilterActivated,
    modelYears,
    categorizedModels,
    dropdownRef,
    isSticky,
    elementBounding,
    dropdownContentRef,
    onHandleCloseButton,
  } = props;
  const translations = useTranslations().filtersSection;
  const isMobile = useIsMobile(Breakpoints.b960);

  const dropdownLabel = useMemo(() => {
    if (Number(appliedFilterAmount) > 0) {
      return `${translations.label} (${appliedFilterAmount})`;
    }
    return `${translations.label}`;
  }, [appliedFilterAmount]);

  return (
    <StyledFilterDropdownContainer
      ref={dropdownRef}
      className="filter-dropdown-container"
      id={'filters'}
      active={toggle}
      isSticky={isSticky}
      modalActive={modalActive}
    >
      {!isMobile && (
        <StyledFilterDropdownButton
          onClick={onHandleToggle}
          className="filter-dropdown-button"
          isSticky={isSticky}
          modalActive={modalActive}
          active={toggle}
        >
          <TextCustom {...labelStyle}>
            <StyledFilterLabelContainer isSticky={isSticky}>
              <Filter />
              {dropdownLabel}
            </StyledFilterLabelContainer>
          </TextCustom>
          <StyledIcon className={`filter-dropdown-icon ${toggle && 'active'}`}>
            <div className={`animation ${toggle && 'active'}`}>
              <ChevronDown />
            </div>
          </StyledIcon>
        </StyledFilterDropdownButton>
      )}
      {(toggle || mobileModalFilterActivated) && (
        <StyledFilterDropdownContent
          className="filter-dropdown-content"
          isSticky={isSticky}
          ref={dropdownContentRef}
          modalActive={modalActive}
          elementBounding={elementBounding}
          isMobile={isMobile}
        >
          {isMobile && (
            <StyledFilterMobileHeader>
              <StyledHeaderTitleContainer>
                <TextCustom {...modalLabelTextStyle}>
                  {translations.label}
                </TextCustom>
              </StyledHeaderTitleContainer>
              <StyledFilterCloseButtonWrapper
                onClick={onHandleCloseButton}
                className="filter-close-button"
              >
                <CloseButton ariaLabel="Close Filters Modal" />
              </StyledFilterCloseButtonWrapper>
            </StyledFilterMobileHeader>
          )}
          <StyledFiltersContainer isSticky={isSticky}>
            {filters && (
              <>
                {modelYears && (
                  <FilterModelYear
                    attributes={modelYears.attributes}
                    appliedFilters={appliedFilters}
                    modalActive={modalActive}
                  />
                )}
                {categorizedModels && (
                  <FilterModels
                    categorizedModels={categorizedModels}
                    appliedFilters={appliedFilters}
                    modalActive={modalActive}
                  />
                )}
              </>
            )}
          </StyledFiltersContainer>
          <BreakpointWrapper min={Breakpoints.b960}>
            <Divider />
          </BreakpointWrapper>
          <StyledButtonsGroup isMobile={isMobile}>
            {isMobile ? (
              /* @ts-ignore */
              <Button
                appearance={ButtonAppearance.Secondary}
                type={ButtonType.Button}
                onClick={onClearAllFilters}
              >
                {translations.deselectAll}
              </Button>
            ) : (
              /* @ts-ignore */
              <LinkButton isInline onClick={onClearAllFilters}>
                <TextCustom
                  appearance={TextAppearance.label100}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize16,
                      lineHeight: TextLineHeight.TextLineHeight24,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {translations.deselectAll}
                </TextCustom>
              </LinkButton>
            )}
            {/* @ts-ignore */}
            <Button
              appearance={ButtonAppearance.Primary}
              type={ButtonType.Button}
              onClick={onViewOffers}
            >
              {`${translations.viewOffers} (${matchingOffers})`}
            </Button>
          </StyledButtonsGroup>
        </StyledFilterDropdownContent>
      )}
    </StyledFilterDropdownContainer>
  );
};

export default DropdownContainer;
