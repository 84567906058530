import React from 'react';

import { useReactiveVar } from '@apollo/client';

import { ModalDisplayContainer } from './styles';
import { ModalDisplayFactory } from './modal-display-factory';
import { modalsData } from '../../store/local-storage';
import { onHandleLayerLoadTracking } from '../../tagging/tagging-helpers';
import { useTrackingManager } from '../../hooks/use-tracking-manager';

const ModalDisplay: React.FC = () => {
  const modalsDataVar = useReactiveVar(modalsData);
  const trackingManager = useTrackingManager();

  const onHandleClose = () => {
    modalsData({ active: false });
    trackingManager &&
      onHandleLayerLoadTracking('close', { ...modalsDataVar }, trackingManager);
  };

  const onHandleLayerLoad = () => {
    trackingManager &&
      onHandleLayerLoadTracking('load', { ...modalsDataVar }, trackingManager);
  };

  return (
    <ModalDisplayContainer className="modal-display-container">
      {modalsDataVar.active ? (
        <ModalDisplayFactory
          mode={modalsDataVar.mode}
          modalProps={{
            ...modalsDataVar,
            onCloseHandler: onHandleClose,
            onLoadHandler: onHandleLayerLoad,
          }}
        />
      ) : (
        undefined
      )}
    </ModalDisplayContainer>
  );
};

export default ModalDisplay;
