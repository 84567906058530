import 'isomorphic-fetch';

import { useFeatureAppConfig } from '../../hooks/use-feature-app-config';
import { useFeatureServices } from '../../hooks/use-feature-services';
import uniqBy from 'lodash/uniqBy';
import { ModelConfig } from '../../typing/model-config';
import { getStringifiedServiceConfigsServiceConfig } from '../../utils/get-stringified-service-configs-service-config';

interface FetchModelsConfigOptions {
  readonly language: string;
  readonly countryCode: string;
  readonly currency: string;
  readonly faServicesUrl: string;
  readonly serviceConfigsServiceConfig?: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
}

export interface ErrorHandlingModel {
  errorCode: string;
  errorMessage: string;
}

export interface ModelsConfigResponse {
  errorHandling: ErrorHandlingModel[];
  fetchedModelsConfig?: Record<string, ModelConfig>;
}

export async function fetchModelsConfigData(
  setModelsConfigData: React.Dispatch<
    React.SetStateAction<ModelsConfigResponse | undefined>
  >,
): Promise<any> {
  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
  } = useFeatureServices();
  const { language, countryCode, currency } = localeService;
  const { faServicesUrl = '', serviceConfig } = useFeatureAppConfig();
  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );

  let fetchedModelsConfig: Record<string, ModelConfig> | undefined;
  let errorHandling: ErrorHandlingModel[] = [];
  const fetchModelsConfigPromise = fetchModelsConfig({
    language,
    countryCode,
    currency,
    faServicesUrl,
    serviceConfigsServiceConfig: serviceConfig || serviceConfigsServiceConfig,
  })
    .then((result: any) => {
      if (result.errorStatus) {
        console.log('Error Fetching Models Config');
        fetchedModelsConfig = undefined;
        errorHandling = getModelsConfigError(errorHandling);
      } else {
        fetchedModelsConfig = result.data;
      }
    })
    .catch((error: Error) => {
      console.log('Error Fetching Models Config', error);
      fetchedModelsConfig = undefined;
      errorHandling = getModelsConfigError(errorHandling);
    });
  Promise.all([fetchModelsConfigPromise]).then(() => {
    if (setModelsConfigData) {
      setModelsConfigData({
        errorHandling,
        fetchedModelsConfig,
      });
    }
  });
}

async function fetchModelsConfig({
  language,
  countryCode,
  currency,
  serviceConfigsServiceConfig,
  faServicesUrl,
}: FetchModelsConfigOptions): Promise<any> {
  const params = new URLSearchParams({ language, countryCode, currency });

  if (serviceConfigsServiceConfig) {
    params.set('serviceConfigsServiceConfig', serviceConfigsServiceConfig);
  }

  params.set('useGlobalConfig', 'true');
  params.set('includeModelYears', 'true');
  try {
    const response = await fetch(createModelConfigUrl(faServicesUrl, params));
    const modelOverview = await response.json();

    if (response.status >= 500) {
      return errorHandler(response, true);
    }

    return errorHandler(modelOverview, false);
  } catch (error) {
    return errorHandler(error, true);
  }
}

function createModelConfigUrl(
  baseUrl: string | undefined,
  params: URLSearchParams,
): string {
  const bffPath = baseUrl?.endsWith('/') ? 'modelsConfig' : '/modelsConfig';

  return `${baseUrl || ''}${bffPath}?${params.toString()}`;
}

const errorHandler = (obj: any, status: boolean) => {
  if (status) {
    return {
      errorStatus: true,
      data: obj,
    };
  }

  return {
    errorStatus: false,
    data: obj,
  };
};

const getModelsConfigError = (errorHandling: ErrorHandlingModel[]) =>
  uniqBy(
    [
      {
        errorCode: 'FMOV-ERR',
        errorMessage: 'Fetch Model Overview Error',
      },
      ...errorHandling,
    ],
    'errorCode',
  );
