import {
  Breakpoints,
  CustomImage,
  Link,
  PinPlace,
  Richtext,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import React from 'react';
import { clearText, stringToPhoneFormat } from '../../../utils/general';
import { AppConstants } from '../../../utils/app-constants';
import DealerInformationDetail from '../../dealer-information-detail';
import {
  StyledDealerCardContainer,
  StyledDealerCardLabel,
  StyledDealerCardImageContainer,
  StyledDealerCardInformation,
  StyledDealerInformationWrapper,
  StyledVehicleVINLabel,
} from './styles';
import {
  dealerNameTextStyle,
  descriptionOverrides,
  vinTextStyle,
} from './helpers';
import CustomerCenter from '../../icons/customer-center';
import { VehicleModel } from '../../../typing/main';
import { ModalHandOffTranslations } from '../../../typing/translations';
import { useFeatureAppConfig } from '../../../hooks/use-feature-app-config';
import { useTrackingManager } from '../../../hooks/use-tracking-manager';
import { useModelsConfig } from '../../../hooks/use-models-config';
import { onVWBasicVehicleTelephoneNumberClickOffers } from '../../../tagging/tagging-helpers';

interface InterstitialCarCardProps {
  vehicle: VehicleModel;
  translations: ModalHandOffTranslations;
  currency: string;
  vehicleImage: string;
}
const InterstitialCarCard: React.FC<InterstitialCarCardProps> = props => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();
  const { vehicle, translations, vehicleImage } = props;
  const modelDescription = translations.dealerStepCard.title.replace(
    '$$modelName$$',
    `${vehicle.modelYear} ${vehicle.model} ${clearText(vehicle.trimLevel)}`,
  );
  const isMobile = useIsMobile(Breakpoints.b560);
  const trackingManager = useTrackingManager();
  const modelsConfig = useModelsConfig();

  const onHandleTelephoneClick = () => {
    const linkParams = {
      url: window.location.href,
      name: undefined,
    };

    trackingManager &&
      onVWBasicVehicleTelephoneNumberClickOffers(
        trackingManager,
        modelsConfig,
        vehicle,
        linkParams,
      );
  };

  return (
    <StyledDealerCardContainer className="dealer-card">
      <StyledDealerCardImageContainer className="dealer-card-image">
        <CustomImage
          src={vehicleImage}
          alt={vehicle.model}
          srcPlaceholder={AppConstants.PlaceHolder480x216}
          srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo480x216}
        />
      </StyledDealerCardImageContainer>
      <StyledVehicleVINLabel className="dealer-card-vin">
        <TextCustom {...vinTextStyle}>VIN {vehicle.vin}</TextCustom>
      </StyledVehicleVINLabel>
      <StyledDealerCardLabel className="dealer-card-label">
        <Richtext
          markdown={modelDescription}
          overrides={descriptionOverrides}
          dangerouslyParseRawHTML
        />
      </StyledDealerCardLabel>
      <StyledDealerCardInformation className="dealer-card-dealer-information">
        <TextCustom {...dealerNameTextStyle}>{vehicle.dealer?.name}</TextCustom>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.p}
          style={{
            fontWeight: TextWeight.light,
            fontSize: TextSize.TextSize10,
            lineHeight: TextLineHeight.TextLineHeight20,
          }}
        >
          {`${vehicle.dealer.distance.toFixed(2)} ${
            translations.dealerStepCard.distance
          }`}
        </TextCustom>

        <StyledDealerInformationWrapper>
          <DealerInformationDetail
            Icon={PinPlace}
            label1={vehicle.dealer?.address1}
            label2={
              vehicle.dealer?.city +
              ', ' +
              vehicle.dealer?.state +
              ' ' +
              vehicle.dealer?.postalcode
            }
          />
          <DealerInformationDetail
            Icon={CustomerCenter}
            label1={
              isMobile ? (
                <Link
                  href={vehicle.dealer ? `tel: ${vehicle.dealer.phone}` : ''}
                  isInline
                  onClick={onHandleTelephoneClick}
                >
                  {stringToPhoneFormat(vehicle.dealer.phone)}
                </Link>
              ) : (
                stringToPhoneFormat(vehicle.dealer.phone)
              )
            }
          />
        </StyledDealerInformationWrapper>
      </StyledDealerCardInformation>
    </StyledDealerCardContainer>
  );
};

export default InterstitialCarCard;
